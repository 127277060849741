<template>
  <div class="pl-2 pr-2">
    <div
      v-if="!cant"
      class="agotado card border-0 single-promo-card mt-2 shadow"
    >
      <div class="card-body card-content" style="font-size: 20px">
        <span class="text-white">
          {{ name }}
        </span>
      </div>
    </div>

    <div
      v-else
      class="background-color card border-0 single-promo-card mt-2 shadow"
    >
      <div class="card-body card-content" style="font-size: 20px">
        <span>
          {{ name }}
        </span>
        <span class="color-secondary ml-1" style="font-weight: bold">
          {{ cant }} <span v-if="dias"> días</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BecuponCardCuponInfo",
  props: {
    name: {
      type: String,
      required: true,
    },
    cant: {
      //   type: Number,
      required: true,
    },
    dias: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.background-color {
  background-color: white;
}

.agotado {
  background-color: red;
}
</style>
