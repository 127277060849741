<template>
  <div>
    <nav-bar />
    <body
      class="bg-body-tertiary page-header-section ptb-100 page-header-bg bg-image"
    >
      <div class="container d-flex flex-column">
        <div v-if="!deleted">
          <h2 class="color-secondary">Borrar cuenta</h2>

          <p>
            Si usted desea eliminar su cuenta de Becupon, ingrese con su correo
            electrónico y contraseña, esto eliminara tanto su cuenta como los
            datos que ingreso anteriormente y ya no podrá utilizar mas ese
            correo para ingresar a la app.
          </p>

          <div class="d-flex flex-column">
            <div class="col-12 col-md-6">
              <label for="email" class="form-label">Correo *</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="email"
                name="email"
                @change="emailChanged"
              />
              <div v-if="emailErrors.length" class="text-danger">
                <ul>
                  <li v-for="error in emailErrors">{{ error }}</li>
                </ul>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <label for="password" class="form-label">Contraseña * </label>

              <div class="d-flex">
                <input
                  v-model="password"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control mr-2"
                  id="password"
                  name="password"
                />
                <button
                  class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                  @click="showPassword = !showPassword"
                >
                  <i v-if="showPassword" class="fas fa-eye-slash"></i>
                  <i v-else class="fas fa-eye"></i>
                </button>
              </div>

              <div v-if="passwordErrors.length" class="text-danger">
                <ul>
                  <li v-for="error in passwordErrors">{{ error }}</li>
                </ul>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <label for="repassword" class="form-label"
                >Repetir contraseña *
              </label>
              <div class="d-flex">
                <input
                  v-model="passwordRepeat"
                  :type="showPasswordRepeat ? 'text' : 'password'"
                  class="form-control mr-2"
                  id="passwordRepeat"
                  name="passwordRepeat"
                />

                <button
                  class="g-recaptcha d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                  @click="showPasswordRepeat = !showPasswordRepeat"
                >
                  <i v-if="showPasswordRepeat" class="fas fa-eye-slash"></i>
                  <i v-else class="fas fa-eye"></i>
                </button>
              </div>
              <div v-if="rePasswordErrors.length" class="text-danger">
                <ul>
                  <li v-for="error in rePasswordErrors">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="mt-5 col-12 col-md-6">
              <div class="col-md-6 col-lg-6 display-contents">
                <input
                  v-if="!sure"
                  class="g-recaptcha w-100 d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                  style="font-size: 30px"
                  type="submit"
                  value="Verificar datos"
                  @click="verify()"
                />
                <div v-else class="d-flex flex-column text-center">
                  <p>¿Esta seguro que desea eliminar su cuenta?</p>

                  <div class="d-flex flex-row justify-content-between">
                    <input
                      class="g-recaptcha w-40 d-flex align-items-center app-download-btn btn btn-success btn-rounded"
                      style="font-size: 30px"
                      type="submit"
                      value="Si"
                      @click="accept()"
                    />

                    <input
                      class="g-recaptcha w-40 d-flex align-items-center app-download-btn btn btn-danger btn-rounded"
                      style="font-size: 30px"
                      type="submit"
                      value="No"
                      @click="cancel()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="d-flex flex-column">
          <p class="text-success">
            La cuenta con el correo {{ this.email }} ha sido borrada
            exitosamente.
          </p>
        </div>
      </div>
    </body>

    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>

<script>
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
import axios from "axios";

const url = process.env.VUE_APP_GRAPHQL_HTTP;

export default {
  name: "DeleteAccount",

  components: {
    NavBar,
    SiteFooterDetails,
    Copyright,
  },

  data() {
    return {
      email: null,
      emailErrors: [],
      passwordErrors: [],
      rePasswordErrors: [],
      userInfo: null,
      password: null,
      passwordRepeat: null,
      showPassword: false,
      showPasswordRepeat: false,
      loginInfo: null,
      token: null,

      userExist: null,
      sure: false,
      deleted: false,
    };
  },

  methods: {
    async emailChanged() {
      this.userExist = false;
      this.userInfo = null;

      this.token = null;
      this.sure = false;
    },

    async verify() {
      this.emailErrors = [];
      this.passwordErrors = [];
      this.rePasswordErrors = [];

      if (!this.email) this.emailErrors.push("Correo requerido.");
      if (!this.password) this.passwordErrors.push("Contraseña requerida.");
      if (this.password !== this.passwordRepeat)
        this.rePasswordErrors.push("Las contraseñas no coinciden.");

      if (!this.userExist && this.email) {
        await axios
          .post(url, {
            query: `
          query getUserByEmail($email:String!){
            userByEmail(email:$email){
              id
              username
              email
              firstName
              lastName
              isActive
            }
          }
          `,
            variables: {
              email: this.email,
            },
          })
          .then((response) => {
            this.userInfo = response.data.data.userByEmail;

            // console.log(this.userInfo);

            if (this.userInfo) {
              // console.log("existe");
              this.userId = this.userInfo.id;
              this.userExist = true;

              if (!this.userInfo.isActive) {
                this.emailErrors.push("Correo no registrado o eliminado.");
                this.userExist = false;
                this.userInfo = null;
              }
            } else {
              this.emailErrors.push("Correo no registrado o eliminado.");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (
        this.userExist &&
        this.password &&
        this.password == this.passwordRepeat
      ) {
        await axios
          .post(url, {
            query: `
          mutation getToken($email:String,$password:String!){
            tokenAuth(email:$email,password:$password){
              token
              success
              user{
                id
                firstName
              }
            }
          }
          `,
            variables: {
              email: this.email,
              password: this.password,
            },
          })
          .then((response) => {
            this.loginInfo = response.data.data.tokenAuth;
            if (this.loginInfo.success) {
              this.token = this.loginInfo.token;
              this.sure = true;

              // console.log(this.loginInfo);
            } else {
              this.passwordErrors.push("Contraseña incorrecta.");
              // console.log(this.loginInfo);
            }
          });
      }
    },

    cancel() {
      this.userExist = false;
      this.userInfo = null;
      this.email = null;
      this.password = null;
      this.passwordRepeat = null;

      this.token = null;
      this.sure = false;
    },

    async accept() {
      const headers = {
        Authorization: `JWT ${this.token}`,
      };

      try {
        const response = await axios.post(
          url,
          {
            query: `
          mutation deleteUser($password:String!){
            deleteUser(password:$password){
              success
            }
          }
          `,
            variables: {
              password: this.password,
            },
          },
          {
            headers: headers,
          }
        );

        const responseData = response.data;
        if (responseData.data && responseData.data.deleteUser.success) {
          // La operación se completó con éxito
          // console.log("Cuenta eliminada con éxito.");
          this.deleted = true;
        } else {
          console.error("Error al eliminar la cuenta.");
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    },
  },
};
</script>
