var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"promo-section ptb-100",class:{ 'gray-light-bg': _vm.isGray },attrs:{"id":"becupons-disponibles"}},[_c('div',{staticClass:"container"},[(
        _vm.couponsList.results
          ? _vm.couponsList.results.length
          : false &&
            _vm.couponsList.results.some((item) => item.newsLetter) &&
            _vm.couponsList.results.some((item) => item.available) &&
            _vm.couponsList.results.some(
              (item) => _vm.fecha_ctual <= item.expirationDate
            )
      )?_c('div',{staticClass:"row"},[_vm._m(0)]):_c('div',{staticClass:"row"},[_vm._m(1)]),_c('div',{staticClass:"d-flex row justify-content-center"},_vm._l((this.couponsList.results),function(coupons){return (
          coupons.available &&
          coupons.newsLetter &&
          _vm.fecha_ctual <= coupons.expirationDate
        )?_c('div',{key:coupons.id,staticClass:"col-md-6 col-lg-4 pl-2 pr-2"},[_c('router-link',{attrs:{"to":{ name: 'Compras', params: { id: coupons.id } },"target":"_blank"}},[_c('div',{staticClass:"card border-0 single-promo-card p-2 mt-4 shadow"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-2 flex justify-content-center"},[_c('img',{staticClass:"inline-flex logo-becoupons",staticStyle:{"border-radius":"8px"},attrs:{"title":coupons.title,"src":coupons.images[0].url,"alt":"Aguacate"}})]),_c('div',{staticClass:"cupons-circle"},[_c('span',{staticClass:"big-number"},[_vm._v(" "+_vm._s(coupons.couponList.length)+" ")]),_c('span',{staticClass:"small-number"},[_vm._v(" "+_vm._s(coupons.couponList.length == "1" ? "Cupón" : "Cupones")+" ")])]),_c('div',{staticClass:"pt-2 pb-3"},[_c('h5',{staticClass:"color-secondary mb-0",attrs:{"title":coupons.title}},[_vm._v(" "+_vm._s(coupons.title)+" ")]),_c('ul',{staticClass:"d-flex"},_vm._l((coupons.cities),function(zona){return _c('li',{key:zona.id,staticClass:"mb-0 location-title"},[_vm._v(" "+_vm._s(zona.name)+", ")])}),0),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"mt-1 inline-flex fas fa-clock icon-size-sx location-icon"}),_c('p',{staticClass:"pl-2 location-info inline-flex mb-0"},[_vm._v(" Válido hasta el "+_vm._s(_vm.formattedDateExpire(coupons.expirationDate))+" ")])]),_c('div',{staticClass:"flex"},[_c('div',[_c('p',{staticClass:"pl-2 location-info inline-flex mb-0"},[_vm._v(" \""+_vm._s(coupons.subTitle)+"\" ")])])])]),_c('div',[(coupons.price)?_c('div',[_c('h5',{staticStyle:{"color":"#ef025c","margin-bottom":"0"}},[_vm._v(" $"+_vm._s(coupons.price .toFixed(2) .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+" ")]),_c('small',{staticStyle:{"font-size":"10px","color":"#ef025c"}},[_vm._v("Precio del BeCupon ")])]):_c('div',[_c('h5',{staticStyle:{"color":"green"}},[_vm._v("GRATIS")])])])])])])])])],1):_vm._e()}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"color-secondary text-center w-100"},[_vm._v(" Algunos de nuestros "),_c('i',[_c('strong',[_vm._v("BeCupons")])]),_vm._v(" disponibles ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"color-secondary text-center w-100"},[_vm._v(" En este momento no existen "),_c('i',[_c('strong',[_vm._v("BeCupons")])]),_vm._v(" disponibles ")])
}]

export { render, staticRenderFns }