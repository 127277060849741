var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-lg-4 pl-2 pr-2 pointer",attrs:{"id":`${_vm.id}_${_vm.num_cupon}`}},[_c('div',{staticClass:"card border-0 single-promo-card p-2 mt-4 shadow",class:{ 'transparent-content': !_vm.available || _vm.isExpired },attrs:{"id":_vm.companyName}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pb-2 flex justify-content-space-between",on:{"click":function($event){return _vm.openCupon(
            _vm.beCuponName,
            _vm.subtitle,
            _vm.name,
            _vm.uses_available,
            _vm.days_expires,
            _vm.real_value,
            _vm.short_description,
            _vm.icon1,
            _vm.imagen1,
            _vm.becupon_description,
            _vm.cupon_description,
            _vm.cupon_locations,
            _vm.uses_cant,
            _vm.companyImage,
            _vm.available,
            _vm.becuponId
          )}}},[_c('img',{staticClass:"inline-flex w-100 border-radius",attrs:{"title":_vm.name,"src":_vm.imagen1,"alt":_vm.name}})]),_c('div',{staticClass:"pt-2 pb-3"},[_c('div',{staticClass:"pt-2 pb-3"},[_c('h5',{staticClass:"color-secondary"},[_vm._v("Información del Cupón:")]),_c('div',{class:_vm.show ? 'showMore' : 'showLess',attrs:{"id":`descrip_${_vm.index}`}},[_vm._v(" "+_vm._s(_vm.cupon_description)+" ")]),_c('a',{class:{ hideButton: _vm.show, showButton: !_vm.show },on:{"click":_vm.showMore}},[_vm._v("ver más...")]),_c('a',{class:{ hideButton: !_vm.show, showButton: _vm.show },on:{"click":_vm.showLess}},[_vm._v("ver menos...")])]),_c('div',{staticClass:"flex",on:{"click":function($event){return _vm.openCupon(
              _vm.beCuponName,
              _vm.subtitle,
              _vm.name,
              _vm.uses_available,
              _vm.days_expires,
              _vm.real_value,
              _vm.short_description,
              _vm.icon1,
              _vm.imagen1,
              _vm.becupon_description,
              _vm.cupon_description,
              _vm.cupon_locations,
              _vm.uses_cant,
              _vm.companyImage,
              _vm.available,
              _vm.becuponId
            )}}},[_c('div',{staticClass:"border-right-my p-4 mr-4"},[_c('img',{staticClass:"inline-flex",staticStyle:{"border-radius":"8px"},attrs:{"width":"50","height":"50","title":_vm.name,"src":_vm.companyImage,"alt":_vm.name}})]),_c('div',{staticClass:"cupons-circle"},[_c('span',{staticClass:"big-number"},[_vm._v(" "+_vm._s(_vm.uses_available)+" ")]),_c('span',{staticClass:"small-number"},[_vm._v(" "+_vm._s(_vm.uses_available == "1" ? "Uso" : "Usos")+" ")])]),_c('div',[_c('h5',{staticClass:"mb-0",attrs:{"title":_vm.name}},[_vm._v(_vm._s(_vm.name))]),_c('h5',{staticClass:"color-secondary mb-0",attrs:{"title":_vm.name}},[_vm._v(" "+_vm._s(_vm.short_description)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.short_description)+" ")]),_c('p',[_vm._v(" (Valor real: $"+_vm._s(_vm.real_value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))+" MXN) ")])])]),_c('div',{on:{"click":function($event){return _vm.openCupon(
              _vm.beCuponName,
              _vm.subtitle,
              _vm.name,
              _vm.uses_available,
              _vm.days_expires,
              _vm.real_value,
              _vm.short_description,
              _vm.icon1,
              _vm.imagen1,
              _vm.becupon_description,
              _vm.cupon_description,
              _vm.cupon_locations,
              _vm.uses_cant,
              _vm.companyImage,
              _vm.available,
              _vm.becuponId
            )}}},[(!_vm.isExpired)?_c('div',[_c('be-cupon-card-cupon-info',{attrs:{"name":"Vence","cant":_vm.formattedDateExpire}})],1):_vm._e(),_c('be-cupon-card-cupon-info',{attrs:{"name":"Usos disponibles","cant":_vm.uses_available}})],1)])])]),_c('div',{staticClass:"agotado"},[(!_vm.available)?_c('div',[_c('be-cupon-card-cupon-info',{attrs:{"name":"Agotado","cant":_vm.available}})],1):_vm._e(),(_vm.isExpired)?_c('div',[_c('be-cupon-card-cupon-info',{attrs:{"name":"Vencido","cant":0}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }