<script>
import carousel from "vue-owl-carousel";
import gql from "graphql-tag";

export default {
  name: "SharePhotos",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    companyList: gql`
      {
        companyList {
          results {
            id
            name
            logo {
              id
              url
            }
          }
        }
      }
    `,

    // result() {
    //   // console.log(this.companyList);
    // },
  },

  components: { carousel },
  data: function () {
    return {
      companyList: [],
    };
  },
};
</script>
<template>
  <section
    id="negocios-asociados"
    class="position-relative feature-section ptb-100"
    v-bind:class="{ 'gray-light-bg': isGray }"
  >
    <div class="container">
      <div v-if="this.companyList.results" class="row">
        <h2 class="color-secondary text-center w-100">
          Comercios asociados a <i><strong>BeCupon</strong></i>
        </h2>
        <div
          v-for="company in this.companyList.results"
          :key="company.id"
          class="col-md-6 col-lg-3 pl-2 pr-2"
        >
          <div class="card border-0 p-2 mt-4 shadow">
            <div class="card-body card-content">
              <img
                class="w-100 border-radius"
                v-bind:title="company.name"
                v-bind:alt="company.name"
                v-bind:src="company.logo.url"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row">
        <h2 class="color-secondary text-center w-100">
          En este momento no existen Comercios asociados a
          <i><strong>BeCupon</strong></i>
        </h2>
      </div>
    </div>
  </section>
</template>
