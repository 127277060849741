<template>
  <section class="page-header-section pt-100">
    <div class="container" style="text-align: center; margin-top: 60px">
      <hr style="margin-bottom: 5rem !important" />
      <div
        class="fa fa-circle-check icon-size-lg mr-3 inline-block"
        style="color: green; font-size: 70px"
      ></div>
      <div style="color: green">
        <span style="font-size: 30px"> Muchas gracias por su compra, </span>
        <div class="mb-3">
          Se ha enviado a su correo el acceso a la aplicación
          <strong>BeCupon</strong>.
        </div>
        <h6 class="text-uppercase">
          Para hacer uso de sus cupones debe descargar la aplicación de
          <strong>BeCupon</strong> en las tiendas
        </h6>
        <p>
          Las aplicaciones de <strong>BeCupon</strong> están disponibles en
          Google Play para dispositivos Android y en AppStore para dispositivos
          iOS
        </p>
        <div class="action-btns mt-4">
          <ul
            class="list-inline"
            style="display: flex; justify-content: center"
          >
            <li class="list-inline-item">
              <a
                :href="appstore"
                target="_blank"
                class="container-download-text d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded w-100"
              >
                <div class="w-100 flex align-items-center">
                  <span class="fab fa-apple icon-size-sm mr-3"></span>
                  <div class="download-text text-left inline-block">
                    <small>Descargar desde</small>
                    <h5 class="mb-0">App Store</h5>
                  </div>
                </div>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                :href="playstore"
                target="_blank"
                class="container-download-text d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded w-100"
              >
                <div class="w-100 flex align-items-center">
                  <span class="fab fa-google-play icon-size-sm mr-3"></span>
                  <div
                    class="download-text text-left inline-block w-fit-content"
                  >
                    <small>Descargar desde</small>
                    <h5 class="mb-0">Google Play</h5>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr style="margin-top: 5rem !important" />
    </div>
  </section>
</template>

<script>
const appstore = process.env.VUE_APP_DOWNLOAD_APPSTORE;
const playstore = process.env.VUE_APP_DOWNLOAD_PLAYSTORE;

export default {
  name: "SuccessInfo",
  props: {},

  data() {
    return {
      appstore: appstore,
      playstore: playstore,
    };
  },
};
</script>
<style scoped lang="scss"></style>
