<template>
  <section
    id="preguntas-frecuentes"
    class="ptb-100"
    v-bind:class="{ 'gray-light-bg': isGray }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-8">
          <div class="section-heading text-center mb-5">
            <h2>Preguntas frecuentes</h2>
            <p>
              A continuación te mostramos una serie de preguntas que podrán
              ayudarte a conocer <strong>BeCupon</strong>. Si aún tienes dudas
              puedes comunicarte con nosotros vía correo electrónico
              <a href="#" title="Enviar email">aquí</a>.
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-12 col-lg-6 mb-5 mb-md-5 mb-sm-5 mb-lg-0">
          <div class="img-wrap">
            <img
              src="../../assets/img/health.png"
              alt="Preguntas frecuentes"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-6">
          <accordian :contents="contents" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
  name: "Faq",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Accordian,
  },
  data: function () {
    return {
      contents: [
        {
          title: "¿Cómo puedo obtener BeCupon?",
          description:
            "Debes registrarte y crear un usuario utilizando tu correo electrónico y número de teléfono.",
          active: true,
        },
        {
          title: "¿Cómo uso mis cupones?",
          description:
            "Una vez que adquieras BeCupon, debes dirigirte a alguna de las sucursales autorizadas, en cada cupón se indica la ubicación con Google Maps.",
          active: false,
        },
        {
          title: "¿Cómo hacer válidos mis cupones?",
          description:
            "Una vez estando en la sucursal solo deberás mostrarle el código QR que tiene el cupón al encargado y el lo escaneará para hacértelo válido. Y a disfrutar de tu descuento 🕺💃!",
          active: false,
        },
        {
          title: "¿Cómo puedo pagar en Oxxo?",
          description:
            "-Selecciona “oxxo” en la forma de pago. -Se te generará un código de barras que debes presentar en la caja de oxxo para pagar. -Una vez efectuado el pago tendras acceso a tu cuponera.",
          active: false,
        },
        {
          title: "¿Qué hacer, si el negocio no hace válido mi cupón?",
          description:
            "En caso de que el negocio, no le haga válido su cupón. Primero debe revisar bien la fecha de vencimiento y las restrinciones del cupón en la descripción. En caso que no tenga respuesta, deberá comunicarse inmediatamente con nosotros al siguiente email atencion@becupon.com. Nosotros te asistiremos.",
          active: false,
        },
      ],
    };
  },
};
</script>
