var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"screenshots-section",class:{
    'gray-light-bg': _vm.isGray,
    'pb-100': _vm.paddingBottom,
    'pt-100': _vm.paddingTop,
  },attrs:{"id":"pantallas-becupon"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"screenshot-wrap"},[_c('carousel',{staticClass:"screen-carousel owl-theme dot-indicator",attrs:{"autoplay":true,"dots":true,"loop":true,"responsive":{
          0: { items: 1 },
          768: { items: 3 },
          991: { items: 4 },
          1200: { items: 4 },
          1920: { items: 4 },
        },"margin":0,"center":true,"nav":false}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-00.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-02.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-03.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-04.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-05.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-06.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-07.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-08.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-09.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-10.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-11.png"),"alt":"screenshots"}}),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/img/Produccion-12.png"),"alt":"screenshots"}})])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-9 col-lg-8"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h2',[_vm._v("Pantallazos de "),_c('strong',[_vm._v("BeCupon")])]),_c('p',[_vm._v(" Te asesoramos para hacer más practico el canje de tus cupones. ")])])])])
}]

export { render, staticRenderFns }