<template>
  <div class="col-md-6 col-lg-4 pl-2 pr-2 pointer" :id="`${id}_${num_cupon}`">
    <div
      class="card border-0 single-promo-card p-2 mt-4 shadow"
      :class="{ 'transparent-content': !available || isExpired }"
      :id="companyName"
    >
      <div class="card-body">
        <div
          @click="
            openCupon(
              beCuponName,
              subtitle,
              name,
              uses_available,
              days_expires,
              real_value,
              short_description,
              icon1,
              imagen1,
              becupon_description,
              cupon_description,
              cupon_locations,
              uses_cant,
              companyImage,
              available,
              becuponId
            )
          "
          class="pb-2 flex justify-content-space-between"
        >
          <img
            class="inline-flex w-100 border-radius"
            :title="name"
            :src="imagen1"
            :alt="name"
          />
        </div>
        <div class="pt-2 pb-3">
          <div class="pt-2 pb-3">
            <h5 class="color-secondary">Información del Cupón:</h5>
            <div
              :id="`descrip_${index}`"
              :class="show ? 'showMore' : 'showLess'"
            >
              {{ cupon_description }}
            </div>
            <a
              :class="{ hideButton: show, showButton: !show }"
              @click="showMore"
              >ver más...</a
            >
            <a
              :class="{ hideButton: !show, showButton: show }"
              @click="showLess"
              >ver menos...</a
            >
          </div>
          <div
            @click="
              openCupon(
                beCuponName,
                subtitle,
                name,
                uses_available,
                days_expires,
                real_value,
                short_description,
                icon1,
                imagen1,
                becupon_description,
                cupon_description,
                cupon_locations,
                uses_cant,
                companyImage,
                available,
                becuponId
              )
            "
            class="flex"
          >
            <div class="border-right-my p-4 mr-4">
              <img
                class="inline-flex"
                style="border-radius: 8px"
                width="50"
                height="50"
                :title="name"
                :src="companyImage"
                :alt="name"
              />
            </div>
            <div class="cupons-circle">
              <span class="big-number">
                {{ uses_available }}
              </span>
              <span class="small-number">
                {{ uses_available == "1" ? "Uso" : "Usos" }}
              </span>
            </div>
            <div>
              <h5 class="mb-0" :title="name">{{ name }}</h5>
              <h5 class="color-secondary mb-0" :title="name">
                {{ short_description }}
              </h5>
              <p>
                {{ short_description }}
              </p>
              <p>
                (Valor real: ${{
                  real_value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
                MXN)
              </p>
            </div>
          </div>
          <div
            @click="
              openCupon(
                beCuponName,
                subtitle,
                name,
                uses_available,
                days_expires,
                real_value,
                short_description,
                icon1,
                imagen1,
                becupon_description,
                cupon_description,
                cupon_locations,
                uses_cant,
                companyImage,
                available,
                becuponId
              )
            "
          >
            <div v-if="!isExpired">
              <be-cupon-card-cupon-info
                name="Vence"
                :cant="formattedDateExpire"
              />
            </div>
            <be-cupon-card-cupon-info
              name="Usos disponibles"
              :cant="uses_available"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="agotado">
      <div v-if="!available">
        <be-cupon-card-cupon-info name="Agotado" :cant="available" />
      </div>

      <div v-if="isExpired">
        <be-cupon-card-cupon-info name="Vencido" :cant="0" />
      </div>
    </div>
  </div>
</template>

<script>
import BeCuponCardCuponInfo from "./BeCuponCardCuponInfo.vue";
export default {
  data() {
    return {
      show: false,
    };
  },
  name: "CuponCard",
  methods: {
    showMore() {
      this.show = true;
    },
    showLess() {
      this.show = false;
    },
    openCupon(
      beCuponName,
      subtitle,
      name,
      uses_available,
      days_expires,
      real_value,
      short_description,
      icon1,
      imagen1,
      becupon_description,
      cupon_description,
      cupon_locations,
      uses_cant,
      companyImage,
      available,
      becuponId
    ) {
      this.$router.push({
        path: `/${beCuponName}/${name}`,
        query: {
          beCuponName: beCuponName,
          subtitle: subtitle,
          becupon_description: becupon_description,
          name: name,
          cupon_description: cupon_description,
          short_description: short_description,
          uses_available: uses_available,
          days_expires: days_expires,
          real_value: real_value,
          icon1: icon1,
          imagen1: imagen1,
          cupon_locations: cupon_locations,
          uses_cant: uses_cant,
          companyImage: companyImage,
          available: available,
          becuponId: becuponId,
        },
      });
    },
  },
  components: {
    BeCuponCardCuponInfo,
  },
  props: {
    becuponId: {
      type: String,
    },
    available: {
      type: Boolean,
    },
    companyImage: {
      type: String,
      required: false,
    },
    companyName: {
      type: String,
    },
    index: {
      type: Number,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    about: {
      type: String,
      required: false,
    },
    num_cupon: {
      type: Number,
      required: false,
    },
    beCuponName: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    becupon_description: {
      type: String,
      required: false,
    },
    cupon_description: {
      type: String,
      required: false,
    },
    short_description: {
      type: String,
      required: false,
    },
    cupon_locations: {
      type: Array(),
      required: false,
    },
    real_value: {
      type: Number,
      required: false,
    },
    date_expire: {
      type: String,
      required: false,
    },
    days_expires: {
      type: String,
      required: false,
    },
    uses_available: {
      type: Number,
      required: false,
    },
    activated_cupons: {
      type: String,
      required: false,
    },
    uses_cant: {
      type: Number,
      required: false,
    },
    icon1: {
      type: String,
      required: false,
    },
    imagen1: {
      type: String,
      required: false,
    },
    icon2: {
      type: String,
      required: false,
    },
    imagen2: {
      type: String,
      required: false,
    },
    icon3: {
      type: String,
      required: false,
    },
    imagen3: {
      type: String,
      required: false,
    },
  },

  created() {},

  computed: {
    formattedDateExpire() {
      const months = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];

      // Dividir la fecha en año, mes y día
      const [year, month, day] = this.date_expire.split("-");

      // Obtener el mes abreviado del array de meses
      const abbreviatedMonth = months[parseInt(month) - 1];

      // Formatear la fecha en el formato deseado: "d mmm yyyy"
      return `${parseInt(day)} ${abbreviatedMonth} ${year}`;
    },

    isExpired() {
      const currentDate = new Date();
      const expireDate = new Date(this.date_expire);

      // Compara las fechas para determinar si el cupón está vencido
      return expireDate < currentDate;
    },
  },
};
</script>

<style scoped lang="scss">
.showMore {
  display: block;
  overflow: hidden;
  height: 100px;
}
.showLess {
  display: block;
  overflow: hidden;
  height: 50px;
}
.hideButton {
  display: none;
}
.pointer {
  cursor: pointer;
}
.cupons-circle {
  width: 100px;
  border-radius: 50%;
  background-color: white;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: bolder;
  color: green;
  border: 1px solid;
  position: absolute;
  top: 4%;
  left: 4%;
}
.small-number {
  display: block;
  margin-top: -64px;
}
.big-number {
  font-size: 55px;
  margin-top: -10px;
  display: block;
}
.background-color {
  background-color: #fff2f6;
}

.agotado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1 !important;
  z-index: 2;
}

.transparent-content {
  opacity: 0.4;
  z-index: 1;
}
</style>
