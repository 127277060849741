var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav-bar'),_c('div',{staticClass:"main"},[_c('section',{staticClass:"page-header-section ptb-100 page-header-bg bg-image",staticStyle:{"opacity":"1"},attrs:{"image-overlay":"8"}},[_c('div',{staticClass:"background-image-wraper page-header-bg"}),_c('div',{staticClass:"container align-items-start"},[_c('div',{staticClass:"flex row mt-4"},[_c('div',{staticClass:"col-md-6 col-lg-3 pl-2 pr-2"},[_c('div',{staticClass:"card border-0 single-promo-card p-2 shadow"},[(this.couponList)?_c('div',{staticClass:"card-content"},[_c('img',{staticClass:"w-100 border-radius",attrs:{"src":this.couponList.results[0].coupons.images[0].url}})]):_vm._e(),(this.couponList)?_c('div',{staticClass:"cupons-circle"},[_c('span',{staticClass:"big-number"},[_vm._v(" "+_vm._s(this.couponList.totalCount)+" ")]),_c('span',{staticClass:"small-number"},[_vm._v(" "+_vm._s(this.couponList.totalCount == "1" ? "Cupón" : "Cupones")+" ")])]):_vm._e()])]),_c('div',{staticClass:"ml-2 flex col-md-4 col-lg-3"},[(this.couponList)?_c('div',[_c('h2',{staticClass:"text-white"},[_vm._v(" "+_vm._s(this.couponList.results[0].coupons.title)+" ")]),_c('h5',{staticClass:"lead text-white"},[_vm._v(" "+_vm._s(this.couponList.results[0].coupons.subTitle)+" ")]),_c('h5',{staticClass:"text-white font-italic"},[_vm._v(" Valor real: $"+_vm._s(this.couponList.results[0].coupons.value .toFixed(2) .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+" MXN ")])]):_vm._e()]),(
              this.couponList
                ? this.couponList.totalCount > 0 &&
                  this.product.amountActives > 0
                : false
            )?_c('div',{staticClass:"col-md-6 col-lg-5 pl-2 pr-2"},[_c('div',[(this.totalPrice)?_c('div',{staticClass:"text-right d-flex flex-column"},[_c('h2',{staticClass:"text-white mb-0"},[_vm._v(" Precio: $"+_vm._s(this.totalPrice .toFixed(2) .replace(/\B(?=(\d{3})+(?!\d))/g, ","))+" MXN ")]),_c('p',{staticClass:"text-white"},[_vm._v("(El precio incluye IVA)")])]):_c('h2',{staticClass:"text-success mb-0 text-right"},[_vm._v("GRATIS")])]),_c('div',{staticClass:"container mt-2"},[_c('div',{staticClass:"row pl-2 pr-2 justify-content"},[_c('div',{staticClass:"col-md-6 col-lg-6 display-contents"},[_c('button',{staticClass:"w-100 d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded",attrs:{"disabled":!_vm.isAtLeastOneAvailable},on:{"click":_vm.comprar}},[_vm._m(0)])])])])]):_c('div',{staticClass:"col-md-6 col-lg-5 pl-2 pr-2"},[_vm._m(1)])])])]),_c('div',{staticClass:"container"},[_vm._m(2),_c('div',{staticStyle:{"width":"90%"}},[(_vm.brands.length)?_c('vueper-slides',{staticClass:"no-shadow",attrs:{"visible-slides":4,"slide-ratio":1 / 4,"slide-multiple":""}},_vm._l((_vm.brands),function(brand,index){return _c('vueper-slide',{key:index,attrs:{"image":brand.image,"link":`#${brand.name}`}})}),1):_vm._e()],1),_c('div',{staticClass:"row pl-2 pr-2"},[_c('div',{staticClass:"card-content mt-4"},[_vm._m(3),(this.couponList)?_c('div',{staticClass:"m-3"},[_vm._v(" "+_vm._s(this.couponList.results[0].coupons.description)+" ")]):_vm._e()])]),(this.couponList)?_c('div',{staticClass:"row pl-2 pr-2"},[_c('card-info',{attrs:{"name":"BeCupons disponibles:","cant":this.product.amountActives}}),_c('card-info',{attrs:{"name":"Cupones activos:","cant":this.couponList.totalCount}}),_c('card-info',{attrs:{"name":"Inicio:","cant":_vm.formatDate(
              new Date(this.couponList.results[0].coupons.initialDate)
            )}}),_c('card-info',{attrs:{"name":"Expiración:","cant":_vm.formatDate(
              new Date(this.couponList.results[0].coupons.expirationDate)
            )}}),_c('card-info',{attrs:{"name":"Precio:","cant":this.precio}}),_c('card-info',{attrs:{"name":"Valor:","cant":this.valor}})],1):_vm._e()]),_c('section',{staticClass:"promo-section ptb-40",class:{ 'gray-light-bg': _vm.isGray }},[_c('div',{staticClass:"container"},[_vm._m(4),(this.couponList)?_c('div',{staticClass:"row"},_vm._l((this.couponList.results),function(cuponT){return _c('cupon-card',{key:cuponT.id,attrs:{"beCuponName":cuponT.title,"subtitle":cuponT.subTitle,"becupon_description":cuponT.description,"name":cuponT.title,"icon1":cuponT.images[0] ? cuponT.images[0].url : null,"imagen1":cuponT.images[0] ? cuponT.images[0].url : null,"short_description":cuponT.smallDescription,"cupon_description":cuponT.description,"real_value":cuponT.valuedAt,"uses_available":cuponT.totalUses,"days_expires":cuponT.expirationDate,"date_expire":cuponT.expirationDate,"uses_cant":cuponT.totalUses,"num_cupon":cuponT.globalUses,"id":cuponT.id,"about":cuponT.description,"company-image":cuponT.company.logo.url,"cupon_locations":cuponT.branchOffices,"available":cuponT.available,"company-name":cuponT.company.name,"becupon-id":_vm.becuponId}})}),1):_vm._e()])])]),_c('site-footer-details',{attrs:{"show-subscribe":true}}),_c('copyright')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"fa fa-cart-shoppin icon-size-md mr-3 inline-block"}),_c('span',{staticClass:"m-2 inline-block",staticStyle:{"font-size":"30px"}},[_vm._v("Comprar BeCupon")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"text-white mb-0"},[_vm._v("No Disponible para ser comprado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex flex-column align-items-center justify-content-center"},[_c('h2',{staticClass:"color-secondary"},[_vm._v("Negocios asociados a este BeCupon")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"color-secondary text-center"},[_vm._v(" Información del "),_c('strong',[_vm._v("BeCupon")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h2',{staticClass:"color-secondary text-center w-100",attrs:{"id":"cupones"}},[_c('i',[_c('strong',[_vm._v("Cupones")])]),_vm._v(" disponibles ")])])
}]

export { render, staticRenderFns }