<template>
  <div
    id="caracteristicas-de-becupon"
    class="feature-section ptb-100"
    v-bind:class="{ 'gray-light-bg': isGray }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-9">
          <div class="section-heading text-center mb-5">
            <h2>Beneficios de BeCupon</h2>
          </div>
        </div>
      </div>

      <!--feature new style start-->
      <div class="row align-items-center justify-content-md-center">
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <i
                  class="fas fa-piggy-bank icon-size-lg mr-4 color-secondary"
                ></i>
                <div class="icon-text">
                  <h5 class="mb-2">Ahorro</h5>
                  <p>Podrás ahorrar dinero en cada comercio asociado.</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <i
                  class="fas fa-credit-card icon-size-lg mr-4 color-secondary"
                ></i>
                <div class="icon-text">
                  <h5 class="mb-2">Compra BeCupon</h5>
                  <p>
                    Puedes pagar con tarjeta en la app o en efectivo en oxxo.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <i
                  class="fas fa-space-shuttle icon-size-lg mr-4 color-secondary"
                ></i>
                <div class="icon-text">
                  <h5 class="mb-2">Rapidez</h5>
                  <p>Fácil navegación para ubicar lo que deseas.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
          <div class="position-relative pb-md-5 py-lg-0">
            <img
              alt="Características de BeCupon"
              src="../../assets/img/Beneficios-de-Becupon.png"
              class="img-center img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <i
                  class="fas fa-laugh-beam icon-size-lg mr-4 color-secondary"
                ></i>
                <div class="icon-text">
                  <h5 class="mb-2">Practicidad</h5>
                  <p>Adquiérela en tres sencillos pasos.</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <i
                  class="fas fa-building icon-size-lg mr-4 color-secondary"
                ></i>
                <div class="icon-text">
                  <h5 class="mb-2">Confianza</h5>
                  <p>Tenemos las mejores opciones de comercios asociados.</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-start mb-sm-0 mb-md-3 mb-lg-3">
                <i class="fas fa-bell icon-size-lg mr-4 color-secondary"></i>
                <div class="icon-text">
                  <h5 class="mb-2">Notificaciones de descuentos</h5>
                  <p>Recibirás notificaciones de descuentos en tiempo real.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--feature new style end-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Features",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
