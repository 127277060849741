<script>
import gql from "graphql-tag";
import axios from "axios";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

const url = process.env.VUE_APP_GRAPHQL_HTTP;

const PRUEBA = gql`
  query CouponList($coupons: ID!) {
    couponList(coupons: $coupons) {
      results {
        id
        title
        subTitle
        description
        branchOffices {
          id
          name
          address
        }
        images {
          id
          url
        }
        smallDescription
        valuedAt
        totalUses
        globalUses
        available
        expirationDate
        initialDate
        amountActives
        company {
          id
          name
          logo {
            url
          }
        }
        coupons {
          id
          title
          subTitle
          description
          initialDate
          expirationDate
          price
          value
          amountActives
          couponAmountActive
          categories {
            id
            name
          }
          cities {
            id
            name
          }
          companies {
            id
            name
            logo {
              url
            }
            branchesOffices {
              id
              name
              address
            }
          }
          images {
            id
            url
          }
          companyImage
        }
      }
      totalCount
    }
  }
`;

export default {
  name: "BeCuponDetails",
  props: {
    // products: {
    //   type: Array,
    //   default: () => [],
    //   required: true,
    // },
    showDesc: {
      type: Boolean,
      default: false,
    },
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: null,
      unit_amount: null,
      name: null,
      description: null,
      image: null,
      success_url: "https://becupon.portalweb.idooproject.com/#/success",
      couponList: null,
      icon1: null,
      imagen1: null,
      first_name: null,
      last_name: null,
      email: null,
      referido: null,
      cupons_id: null,
      crumbs: [
        { link: "#", name: "Inicio", isActive: false },
        { link: "#", name: "Somos BeCupon", isActive: true },
      ],
      iva: null,
      fee: null,
      subtotalIva: null,

      totalPrice: null,
      companyImage: "",

      precio: null,
      valor: null,

      isAtLeastOneAvailable: true,

      brandsSet: new Set(),
      brands: [],

      becuponId: "",
    };
  },
  async mounted() {
    await this.$apollo
      .query({
        query: PRUEBA,
        variables: {
          coupons: this.$route.params.id,
        },
      })
      .then((response) => {
        this.couponList = response.data.couponList;
        // console.log(this.couponList);

        this.product = response.data.couponList.results[0].coupons;
        // console.log(this.product);

        this.isAtLeastOneAvailable = this.couponList.results.some(
          (coupon) => coupon.available
        );

        // console.log(this.isAtLeastOneAvailable);
      });

    await axios
      .post(url, {
        query: `
      {
  globalConfigurationList {
    results {
      name
      val
    }
  }
}

        `,
      })
      .then((response) => {
        this.iva = response.data.data.globalConfigurationList.results.filter(
          (item) => item.name === "iva_app"
        )[0].val;

        this.fee = parseInt(
          response.data.data.globalConfigurationList.results.filter(
            (item) => item.name === "fee_app"
          )[0].val
        );

        this.subtotalIva = (this.product.price + this.fee) * this.iva;

        if (this.product.price > 0) {
          this.totalPrice = this.product.price + this.fee + this.subtotalIva;
        } else {
          this.totalPrice = 0;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    this.precio = `$${this.couponList.results[0].coupons.price
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MXN`;
    this.valor = `$${this.couponList.results[0].coupons.value
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MXN`;

    this.couponList.results.forEach((coupon) => {
      const name = coupon.company.name;
      this.brandsSet.add(name); // Agrega el nombre de la empresa al conjunto
    });

    this.brands = Array.from(this.brandsSet).map((name) => {
      // Recupera la URL de la imagen correspondiente al nombre de la empresa
      const companyImageURL =
        this.couponList.results.find((coupon) => coupon.company.name === name)
          ?.company.logo.url || "";

      return { image: companyImageURL, name: name };
    });
  },
  components: {
    CuponCard,
    NavBar,
    SiteFooterDetails,
    Copyright,
    CardInfo,
    PageHeaderDetails,
    CuponsList,
    InfoDetails,
    VueperSlides,
    VueperSlide,
  },
  methods: {
    comprar() {
      this.$router.push("/comprar/" + this.product.id);
    },
    anterior() {
      this.$router.go(-1);
    },
    siguiente() {
      this.$router.go(1);
    },

    formatDate(date) {
      const months = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];

      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();

      return `${day}  ${month}  ${year}`;
    },
  },

  watch: {},
  created() {
    this.becuponId = this.$route.params.id;
    // console.log(this.becuponId);
  },
  computed: {
    emptyCart() {
      return this.couponList.results[0].length < 1;
    },
    total() {
      return this.couponList.results[0].coupons.price;
    },
  },
  filters: {
    currency(val) {
      return `${val.toFixed(2)} €`;
    },
  },
};
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
import PageHeaderDetails from "../components/becuponDetails/PageHeaderDetails";
import InfoDetails from "../components/becuponDetails/InfoDetails.vue";
import CuponsList from "../components/becuponDetails/CuponsList";
import CardInfo from "../components/becuponDetails/CardInfo.vue";
import CuponCard from "../components/becuponDetails/CuponCard.vue";

import BuyButton from "../components/becuponDetails/BuyButton.vue";
</script>
<template>
  <div>
    <nav-bar />
    <div class="main">
      <section
        class="page-header-section ptb-100 page-header-bg bg-image"
        image-overlay="8"
        style="opacity: 1"
      >
        <div class="background-image-wraper page-header-bg"></div>
        <div class="container align-items-start">
          <div class="flex row mt-4">
            <div class="col-md-6 col-lg-3 pl-2 pr-2">
              <div class="card border-0 single-promo-card p-2 shadow">
                <div class="card-content" v-if="this.couponList">
                  <img
                    class="w-100 border-radius"
                    v-bind:src="
                      this.couponList.results[0].coupons.images[0].url
                    "
                  />
                </div>
                <div class="cupons-circle" v-if="this.couponList">
                  <span class="big-number">
                    {{ this.couponList.totalCount }}
                  </span>
                  <span class="small-number">
                    {{
                      this.couponList.totalCount == "1" ? "Cupón" : "Cupones"
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="ml-2 flex col-md-4 col-lg-3">
              <div v-if="this.couponList">
                <!-- <h5 class="text-white mb-0">BeCupon:</h5> -->
                <h2 class="text-white">
                  {{ this.couponList.results[0].coupons.title }}
                </h2>
                <h5 class="lead text-white">
                  {{ this.couponList.results[0].coupons.subTitle }}
                </h5>
                <h5 class="text-white font-italic">
                  Valor real: ${{
                    this.couponList.results[0].coupons.value
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                  MXN
                </h5>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-5 pl-2 pr-2"
              v-if="
                this.couponList
                  ? this.couponList.totalCount > 0 &&
                    this.product.amountActives > 0
                  : false
              "
            >
              <div>
                <div
                  v-if="this.totalPrice"
                  class="text-right d-flex flex-column"
                >
                  <h2 class="text-white mb-0">
                    Precio: ${{
                      this.totalPrice
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                    MXN
                  </h2>
                  <p class="text-white">(El precio incluye IVA)</p>
                </div>

                <h2 class="text-success mb-0 text-right" v-else>GRATIS</h2>
              </div>

              <div class="container mt-2">
                <div class="row pl-2 pr-2 justify-content">
                  <div class="col-md-6 col-lg-6 display-contents">
                    <button
                      class="w-100 d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                      @click="comprar"
                      :disabled="!isAtLeastOneAvailable"
                    >
                      <div class="w-100">
                        <div class="text-center">
                          <span
                            class="fa fa-cart-shoppin icon-size-md mr-3 inline-block"
                          ></span>
                          <span class="m-2 inline-block" style="font-size: 30px"
                            >Comprar BeCupon</span
                          >
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-5 pl-2 pr-2" v-else>
              <div class="text-center">
                <h4 class="text-white mb-0">No Disponible para ser comprado</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container">
        <div
          class="mt-3 d-flex flex-column align-items-center justify-content-center"
        >
          <h2 class="color-secondary">Negocios asociados a este BeCupon</h2>
        </div>
        <div style="width: 90%">
          <vueper-slides
            :visible-slides="4"
            :slide-ratio="1 / 4"
            slide-multiple
            class="no-shadow"
            v-if="brands.length"
          >
            <vueper-slide
              v-for="(brand, index) in brands"
              :key="index"
              :image="brand.image"
              :link="`#${brand.name}`"
            >
            </vueper-slide>
          </vueper-slides>
        </div>
        <div class="row pl-2 pr-2">
          <div class="card-content mt-4">
            <h2 class="color-secondary text-center">
              Información del <strong>BeCupon</strong>
            </h2>
            <div class="m-3" v-if="this.couponList">
              {{ this.couponList.results[0].coupons.description }}
            </div>
          </div>
        </div>
        <div class="row pl-2 pr-2" v-if="this.couponList">
          <card-info
            name="BeCupons disponibles:"
            :cant="this.product.amountActives"
          />

          <card-info
            name="Cupones activos:"
            :cant="this.couponList.totalCount"
          />

          <card-info
            name="Inicio:"
            :cant="
              formatDate(
                new Date(this.couponList.results[0].coupons.initialDate)
              )
            "
          />

          <card-info
            name="Expiración:"
            :cant="
              formatDate(
                new Date(this.couponList.results[0].coupons.expirationDate)
              )
            "
          />

          <card-info name="Precio:" :cant="this.precio" />

          <card-info name="Valor:" :cant="this.valor" />
        </div>
      </div>

      <section
        class="promo-section ptb-40"
        v-bind:class="{ 'gray-light-bg': isGray }"
      >
        <div class="container">
          <div class="row">
            <h2 class="color-secondary text-center w-100" id="cupones">
              <i><strong>Cupones</strong></i> disponibles
            </h2>
          </div>
          <div class="row" v-if="this.couponList">
            <cupon-card
              v-for="cuponT in this.couponList.results"
              :key="cuponT.id"
              :beCuponName="cuponT.title"
              :subtitle="cuponT.subTitle"
              :becupon_description="cuponT.description"
              :name="cuponT.title"
              :icon1="cuponT.images[0] ? cuponT.images[0].url : null"
              :imagen1="cuponT.images[0] ? cuponT.images[0].url : null"
              :short_description="cuponT.smallDescription"
              :cupon_description="cuponT.description"
              :real_value="cuponT.valuedAt"
              :uses_available="cuponT.totalUses"
              :days_expires="cuponT.expirationDate"
              :date_expire="cuponT.expirationDate"
              :uses_cant="cuponT.totalUses"
              :num_cupon="cuponT.globalUses"
              :id="cuponT.id"
              :about="cuponT.description"
              :company-image="cuponT.company.logo.url"
              :cupon_locations="cuponT.branchOffices"
              :available="cuponT.available"
              :company-name="cuponT.company.name"
              :becupon-id="becuponId"
            />
          </div>
        </div>
      </section>
    </div>

    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>
<style>
.display-contents {
  display: contents;
}

.color-on-top {
  background-color: white;
}

.justify-content {
  justify-content: center;
}

.text-white {
  color: #ffffff;
  display: inline-block;
}

.inline-block {
  display: inline-block;
}

.justify-between {
  justify-content: space-between;
  align-items: center;
}
.border-right-my {
  border-right: 2px solid black;
}

.btn.btn-brand-02:disabled {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

@media (max-width: 768px) {
}
</style>
