<template>
    <div 
    class="col-md-6 col-lg-4 pl-2 pr-2">
        <div class="background-color card border-0 single-promo-card p-2 mt-4 shadow">
            <div class="card-body card-content" style="font-size: 20px;">
                <span>
                    {{name}}
                </span>
                <span class="color-secondary ml-1" style="font-weight: bold;">
                    {{ cant }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardInfo',
        props: {
            name: {
                type: String,
                required: true
            },
            cant: {
                type: String,
                required: false
            },
        }
    }
</script>

<style scoped lang="scss">
.background-color {
    background-color: white;
}
</style>