<template>
  <header id="inicio" class="header">
    <!-- <div v-if="home">Hola mundo</div> -->
    <nav
      class="navbar navbar-expand-lg fixed-top"
      image-overlay="8"
      v-bind:class="{
        affix: hasAffix,
        'custom-nav': coloredLogo,
        'bg-transparent': !coloredLogo,
      }"
    >
      <div class="container">
        <router-link v-if="home" to="/" v-scroll-to="'#inicio'">
          <img
            src="../../assets/img/logo-color.png"
            alt="logo-becupon"
            class="img-fluid"
            style="max-width: 114px !important"
          />
        </router-link>
        <router-link v-if="!home" to="/">
          <img
            src="../../assets/img/logo-color.png"
            alt="logo-becupon"
            class="img-fluid"
            style="max-width: 114px !important"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          @click="mobileNavClicked"
          v-bind:class="{ collapsed: collapsed }"
        >
          <span class="ti-menu"></span>
        </button>
        <div
          class="collapse navbar-collapse h-auto"
          v-bind:class="{ show: !collapsed }"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ml-auto menu">
            <li>
              <router-link v-if="home" to="/" v-scroll-to="'#inicio'"
                >Inicio</router-link
              >
              <router-link v-if="!home" to="inicio/#">Inicio</router-link>
            </li>
            <li>
              <router-link
                v-if="home"
                to="/#becupons-disponibles"
                v-scroll-to="'#becupons-disponibles'"
              >
                Cupones disponibles
              </router-link>
              <router-link
                v-if="!home"
                to="/#becupons-disponibles"
                v-scroll-to="'#becupons-disponibles'"
              >
                Cupones disponibles
              </router-link>
            </li>
            <li>
              <router-link
                v-if="home"
                class="dropdown-toggle"
                to="/#somos-becupon"
                v-scroll-to="'#somos-becupon'"
                >Somos BeCupon</router-link
              >
              <router-link
                v-if="!home"
                class="dropdown-toggle"
                to="inicio/#somos-becupon"
                >Somos BeCupon</router-link
              >
              <ul class="sub-menu">
                <li>
                  <router-link
                    v-if="home"
                    to="/#somos-becupon"
                    v-scroll-to="'#somos-becupon'"
                    >BeCupon</router-link
                  >
                  <router-link v-if="!home" to="inicio/#somos-becupon"
                    >BeCupon</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-if="home"
                    to="/#negocios-asociados"
                    v-scroll-to="'#negocios-asociados'"
                    >Negocios asociados</router-link
                  >
                  <router-link v-if="!home" to="inicio/#negocios-asociados"
                    >Negocios asociados</router-link
                  >
                </li>
                <li>
                  <router-link
                    v-if="home"
                    to="/#pantallas-becupon"
                    v-scroll-to="'#pantallas-becupon'"
                    >Pantallas</router-link
                  >
                  <router-link v-if="!home" to="inicio/#pantallas-becupon"
                    >Pantallas</router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <router-link
                v-if="home"
                to="/#caracteristicas-de-becupon"
                v-scroll-to="'#caracteristicas-de-becupon'"
                >Beneficios</router-link
              >
              <router-link v-if="!home" to="inicio/#caracteristicas-de-becupon"
                >Beneficios</router-link
              >
            </li>
            <li>
              <router-link
                v-if="home"
                to="/#proceso-de-uso"
                v-scroll-to="'#proceso-de-uso'"
                >Guía de uso</router-link
              >
              <router-link v-if="!home" to="inicio/#proceso-de-uso"
                >Guía de uso</router-link
              >
            </li>
            <li>
              <router-link
                v-if="home"
                to="/#preguntas-frecuentes"
                v-scroll-to="'#preguntas-frecuentes'"
                >FaQ</router-link
              >
              <router-link v-if="!home" to="inicio/#preguntas-frecuentes"
                >FaQ</router-link
              >
            </li>
            <li>
              <router-link v-if="home" to="/#contacto" v-scroll-to="'#contacto'"
                >Contacto</router-link
              >
              <router-link v-if="!home" to="inicio/#contacto"
                >Contacto</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  props: {
    coloredLogo: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      home: false,
      windowTop: 0,
      collapsed: true,
    };
  },
  mounted() {
    // alert(window.location.pathname.includes('/inicio'));
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/#" ||
      window.location.pathname.includes("/inicio")
    ) {
      this.home = true;
    }
    window.addEventListener("scroll", this.onScroll);
    // function goToPage(event) {
    //   event.preventDefault(); // stop the browser from navigating to the destination URL.
    //   if (
    //     event.target.getAttribute("href") !== "#" &&
    //     event.target.getAttribute("href") !== "/#"
    //   ) {
    //     console.log("Href: ", event.target.getAttribute("href"));
    //     const hrefUrl = event.target.getAttribute("href");
    //     window.history.pushState({}, window.document.title, hrefUrl); // Update URL as well as browser history.
    //     const linkTag = document.createElement("link");
    //     linkTag.setAttribute("rel", "canonical");
    //     linkTag.href = "https://www.becupon.com" + hrefUrl;
    //     document.head.appendChild(linkTag);
    //   }
    // }

    // Enable client-side routing for all links on the page
    // document
    //   .querySelectorAll("a")
    //   .forEach((link) => link.addEventListener("click", goToPage));
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll: function () {
      this.windowTop = window.top.scrollY;
    },
    mobileNavClicked: function () {
      this.collapsed = !this.collapsed;
    },
  },
  computed: {
    hasAffix: function () {
      return this.windowTop > 0;
    },
  },
};
</script>
