<template>
  <div>
    <button
      @click="handlePayment"
      :disabled="processing"
      class="g-recaptcha w-100 d-flex align-items-center app-download-btn btn btn-success btn-rounded"
      style="font-size: 30px"
    >
      <span v-if="amount"> Comprar BeCupon </span>

      <span v-else> Adquirir BeCupon </span>
    </button>
  </div>
</template>

<script>
import axios from "axios";

const url = process.env.VUE_APP_GRAPHQL_HTTP;

export default {
  name: "StripeButton",

  props: {
    amount: Number, // Monto del pago en pesos mexicanos (MXN)
    description: String, // Descripción del producto/servicio
    productImage: String,
    email: String,
    cuponId: String, // ID del cupón
    iva: String, // Impuesto al valor agregado
    fee: Number, // Tarifa adicional
    userId: String,
    token: String,
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    async handlePayment() {
      try {
        const headers = {
          Authorization: `JWT ${this.token}`, // Agrega el token en el encabezado
        };

        const variables = {
          origin: "app_web",
          userId: this.userId,
          newPayment: {
            amount: this.amount * 100,
            shoppingCars: `[(${this.cuponId}, 1)]`,
            purchaseReservations: [],
            type: "C",
            iva: this.iva,
            fee: this.fee,
          },
        };

        // Realiza la mutación GraphQL usando Axios
        const response = await axios.post(
          url,
          {
            query: `
              mutation CreatePayment($origin: String!, $userId: Int!, $newPayment: PaymentsInput){
                createPayment(
                  origin: $origin,
                  userId: $userId,
                  newPayment: $newPayment,
                  withDiscount: false
                ) {
                  ok
                  urlStripe
                  payment
                  errors {
                    messages
                  }
                }
              }
            `,
            variables,
          },
          { headers }
        );

        const responseData = response.data.data.createPayment;

        if (responseData.ok) {
          // Redirige a la URL de Stripe
          if (responseData.urlStripe) {
            window.location.href = responseData.urlStripe;
          } else {
            window.onload = () => {
              window.scrollTo(0, 0);
            };

            window.location.href =
              `${window.location.origin}/#/success` +
              `?amountInCents=${0}&cuponId=${
                this.cuponId
              }&iva=${0}&fee=${0}&userId=${this.userId}`;
          }
        } else {
          // Maneja errores aquí si es necesario
          console.error(responseData.errors.messages);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },

  mounted() {},
};
</script>
