<script>
export default {
  name: "PoliticasPage",
  components: {
    NavBar,
    SiteFooterDetails,
    Copyright,
  },
  methods: {
    anterior() {
      this.$router.go(-1);
    },
  },
};
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
</script>
<template>
  <div>
    <nav-bar />
    <body
      class="bg-body-tertiary page-header-section ptb-100 page-header-bg bg-image"
    >
      <div class="container">
        <main>
          <p
            style="
              margin-top: 50pt;
              margin-left: 35.4pt;
              margin-bottom: 8pt;
              text-indent: -35.4pt;
              text-align: center;
              line-height: 108%;
              font-size: 14pt;
            "
          >
            <strong>“AVISO DE PRIVACIDAD PARA USUARIOS DE LA </strong>
          </p>
          <p
            style="
              margin-top: 0pt;
              margin-bottom: 8pt;
              text-align: center;
              line-height: 108%;
              font-size: 14pt;
            "
          >
            <strong>APLICACIÓN BECUPON”</strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: center">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: center">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: center">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 11.67pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 24.33pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>OBJETO</u>.
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >El objeto de la Aplicación Tecnológica BECUPON (En lo sucesivo la
              “Aplicación”), es el de brindar atención temprana de servicios a
              los usuarios y empresas mediante su acceso emitido por la empresa
              BECUPON, puedan acceder a la compra de BECUPONs.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="2" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 15pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 21pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>IDENTIFICACION DEL RESPONSABLE</u>.
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >De conformidad con lo establecido en la Ley Federal de Protección
              de Datos Personales en Posesión de los Particulares (En lo
              sucesivo la “Ley”) y su Reglamento, le informamos que BECUPON y/o
              sus filiales o subsidiarias (En lo sucesivo el “Responsable”),
              tratará los datos personales que Usted como titular nos
              proporcione en términos del presente Aviso de Privacidad (En lo
              sucesivo “Aviso”).</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="3" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 18.34pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 17.66pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>CONCEPTOS</u>.
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.1. </span
            ><strong
              ><span style="font-family: Arial">Consentimiento:</span></strong
            ><span style="font-family: Arial">
              Manifestación de la voluntad del titular de los datos mediante la
              cual se efectúa el tratamiento de los mismos.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <br /><span style="font-family: Arial">III.2. </span
            ><strong
              ><span style="font-family: Arial">Datos Personales:</span></strong
            ><span style="font-family: Arial">
              Cualquier información concerniente a una persona física
              identificada o identificable.
            </span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.3. </span
            ><strong
              ><span style="font-family: Arial"
                >Datos Personales Sensibles:
              </span></strong
            ><span style="font-family: Arial"
              >Aquellos datos personales íntimos</span
            ><span style="font-family: Arial">&#xa0; </span
            ><span style="font-family: Arial"
              >del Titular de los Datos, que por ejemplo puedan revelar aspectos
              raciales, étnicos, estado de salud presente o futuro, información
              genética, opiniones políticas, preferencia sexual, creencias
              religiosas, filosóficas y morales.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.4. </span
            ><strong
              ><span style="font-family: Arial">Responsable: </span></strong
            ><span style="font-family: Arial">&#xa0;</span
            ><span style="font-family: Arial"
              >Persona física o moral de carácter privado que decide sobre el
              tratamiento de datos personales.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.5. </span
            ><strong><span style="font-family: Arial">Tercero: </span></strong
            ><span style="font-family: Arial"
              >La persona física o moral, nacional o extranjera, distinta al
              titular o del responsable de los datos.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.6. </span
            ><strong><span style="font-family: Arial">Usuario</span></strong
            ><span style="font-family: Arial"> </span
            ><strong><span style="font-family: Arial">o</span></strong
            ><span style="font-family: Arial"> </span
            ><strong><span style="font-family: Arial">Titular</span></strong
            ><span style="font-family: Arial"
              >: La persona física a quien corresponden los datos
              personales.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.7. </span
            ><strong
              ><span style="font-family: Arial">Tratamiento: </span></strong
            ><span style="font-family: Arial"
              >La obtención, uso, divulgación o almacenamiento de datos
              personales por cualquier medio.
            </span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.8. </span
            ><strong
              ><span style="font-family: Arial">Transferencia: </span></strong
            ><span style="font-family: Arial"
              >Toda comunicación de datos realizada a persona distinta del
              responsable o encargado del tratamiento.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.9. </span
            ><strong
              ><span style="font-family: Arial">Entorno digital: </span></strong
            ><span style="font-family: Arial"
              >Es el ámbito conformado por la conjunción de hardware, software,
              redes, aplicaciones, servicios o cualquier tecnología de la
              sociedad de la información que permiten el intercambio o
              procesamiento informatizado o digitalizado de datos.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">III.10. </span
            ><strong><span style="font-family: Arial">Cookies: </span></strong
            ><span style="font-family: Arial"
              >Las cookies son archivos de texto que son descargados
              automáticamente y almacenados en el disco duro del equipo de
              computo del Usuario al navegar por una pagina de internet
              especifica o aplicación tecnológica, que permiten recordar al
              servidor de internet algunos datos sobre este Usuario, entre
              ellos, sus preferencias para la visualización de las páginas en
              ese servidor, nombre y contraseña.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="4" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 19.67pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 16.33pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>DATOS PERSONALES QUE RECABAMOS</u>.
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >Los datos personales que son recabados por el Responsable de
              manera enunciativa, mas no limitativa al momento de registrarse
              son: Nombre completo, fecha de nacimiento, numero de teléfono
              celular, corre electrónico.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="5" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 16.34pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 19.66pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u
                >FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES QUE
                RECABAMOS.</u
              >
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >La finalidad del tratamiento de los datos personales que
              recabamos de nuestros Usuarios son los siguientes.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">V.1.</span
            ><span style="font-family: Arial">&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family: Arial"
              >Brindar atención a través de la aplicación tecnológica BECUPON a
              todos aquellos usuarios que deseen realizar la compra de uno o más
              de nuestros BECUPONs.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">V.2.</span
            ><span style="font-family: Arial">&#xa0;&#xa0;&#xa0;&#xa0; </span
            ><span style="font-family: Arial"
              >Para los casos en el que el Responsable trate los datos de los
              Usuarios de forma distinta la necesaria e inherente para la
              consecución de los fines de la utilización de la Aplicación, se le
              requerirá al Titular para que proporcione su consentimiento si así
              lo deseare.
            </span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="6" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 19.67pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 16.33pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>COOKIES Y OTRAS TECNOLOGÍAS.</u>
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >Nuestra Aplicación para teléfonos inteligentes NO utiliza cookies
              y otras tecnologías a través de las cuales es posible monitorear
              su comportamiento como usuario de internet.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="7" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 23.01pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 12.99pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u
                >MEDIO PARA LIMITAR EL USO O DIVULGACION DE LOS DATOS
                PERSONALES.</u
              >
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >Si deseas que sea limitado el uso o divulgación de los datos
              personales, o si tienes alguna duda relacionada con lo anterior,
              deberás enviar un correo electrónico a la dirección
              info@becupon.com, en el cual podrás manifestar las limitaciones
              que deseas que se practiquen al uso o divulgación de tus datos
              personales.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="8" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 26.34pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 9.66pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>MODIFICACIONES AL AVISO DE PRIVACIDAD.</u>
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >Nos reservamos el derecho de efectuar en cualquier momento
              modificaciones o actualizaciones al presente Aviso de Privacidad,
              para la atención de novedades legislativas o jurisprudenciales.
              Estas modificaciones estarán disponibles en nuestra Aplicación en
              todo momento.
            </span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="9" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 19.67pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 16.33pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>MEDIDAS DE SEGURIDAD.</u>
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">El Responsable</span
            ><span style="font-family: Arial">&#xa0; </span
            ><span style="font-family: Arial"
              >cuenta con las medidas de seguridad ya sea tecnológicas,
              informáticas, y administrativas necesarias para la protección,
              limitación y custodia de sus datos personales contra daño o
              perdida, alteración, destrucción o el uso, acceso o tratamiento no
              autorizado.
            </span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <ol start="10" type="I" style="margin: 0pt; padding-left: 0pt">
            <li
              style="
                margin-left: 16.34pt;
                margin-bottom: 8pt;
                text-align: justify;
                padding-left: 19.66pt;
                font-family: Arial;
                font-weight: bold;
              "
            >
              <u>CONSENTIMIENTO.</u>
            </li>
          </ol>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial"
              >El presente Aviso de Privacidad describe nuestras prácticas de
              privacidad y tratamiento de los datos personales para la servicios
              de la Aplicación. Al dar Click en el botón de “Aceptar”, o al
              utilizar los servicios de la Aplicación, Usted declara que ha
              leído, entendido, aceptado y otorgado su consentimiento a estas
              políticas y Aviso de Privacidad y lo acepta el cliente una vez es
              generado su usuario.</span
            >
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <strong><span style="font-family: Arial">&#xa0;</span></strong>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
          <p style="margin-top: 0pt; margin-bottom: 8pt; text-align: justify">
            <span style="font-family: Arial">&#xa0;</span>
          </p>
        </main>
      </div>
    </body>
    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>
<style>
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css";

.input,
select {
  margin-left: 10px;
}
</style>
