<template>
  <section
    class="page-header-section ptb-100 page-header-bg bg-image"
    image-overlay="8"
    style="opacity: 1"
  >
    <div class="background-image-wraper page-header-bg"></div>
    <div class="container">
      <div class="row align-items-center">
        <span class="ml-2 flex col-md-4 col-lg-4">
          <div>
            <h5 class="text-white mb-0">BeCupon:</h5>
            <h1 class="text-white mb-0">{{ title }}</h1>
            <p class="lead text-white">{{ subtitle }}</p>
          </div>
        </span>
        <div class="col-md-6 col-lg-2 pl-2 pr-2">
          <div class="card border-0 single-promo-card p-2 mt-4 shadow">
            <div class="card-body card-content">
              <img
                class="w-100 border-radius"
                :title="title"
                :alt="title"
                :src="aguacate"
              />
            </div>
          </div>
        </div>
        <div>
          <!-- <buy-button :top="false" /> -->
        </div>
      </div>
      <div class="row">
        <header-card
          v-for="(cupon, index) in cupons"
          :key="index"
          :about="cupon.about"
          :id="cupon.id"
          :num_cupon="cupon.num_cupon"
          :imageUrl="cupon.imageUrl"
        />
      </div>
    </div>
  </section>
</template>

<script>
import HeaderCard from "./HeaderCard.vue";
import aguacate from "@../../../public/assets/img/aguacate.png";
import ohzone from "@../../../public/assets/img/logo-ohzone.png";
import sharkys from "@../../../public/assets/img/logo-sharkys.jpeg";
// import BuyButton from "./BuyButton.vue";

export default {
  data() {
    return {
      aguacate: aguacate,
      ohzone: ohzone,
      sharkys: sharkys,
    };
  },
  created() {
    for (let i = 0; i < this.cupons.length; i++) {
      if (i === 0) {
        this.cupons[i].imageUrl = aguacate;
      } else if (i === 1) {
        this.cupons[i].imageUrl = ohzone;
      } else if (i === 2) {
        this.cupons[i].imageUrl = sharkys;
      }
    }
  },
  components: {
    HeaderCard,
    // BuyButton
  },
  props: {
    title: {
      default: "Page Title",
      type: String,
    },
    subtitle: {
      default: "Page Subtitle",
      type: String,
    },
    cupons: {
      type: Array(),
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.page-header-bg {
  background: url("../../assets/img/cta-bg-details.png");
}
</style>
