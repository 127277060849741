<template>
  <section
    class="page-header-section page-header-bg bg-image ptb-100"
    image-overlay="8"
    style="opacity: 1"
  >
    <div class="background-image-wraper page-header-bg"></div>
    <div class="container">
      <div class="row align-items-left mb-4" @click="goToBack">
        <i
          class="fas fa-chevron-left icon-size-md"
          style="color: white"
          title="Atrás"
        >
          <span class="back" style="font-size: 35px">Atrás</span>
        </i>
      </div>
      <div class="row align-items-center">
        <span class="ml-2 flex col-md-4 col-lg-4">
          <div class="flex flex-column">
            <!-- <h5 class="text-white mb-0">BeCupon:</h5> -->
            <h1 class="text-white mb-0">{{ title }}</h1>
            <p class="lead text-white">{{ subtitle }}</p>
          </div>
        </span>
        <div class="col-md-6 col-lg-2 pl-2 pr-2">
          <div class="card border-0 single-promo-card p-2 mt-4 shadow">
            <div class="card-body card-content">
              <img
                class="w-100 border-radius"
                title="Aguacate"
                alt="Aguacate"
                :src="companyImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    title: {
      default: "Cupon Name",
      type: String,
    },
    subtitle: {
      default: "BeCupon Name",
      type: String,
    },
    companyImage: {
      type: String,
    },
    becuponId: {
      type: String,
    },
  },

  created() {},
  methods: {
    goToBack() {
      this.$router.replace(`/compras/${this.becuponId}`);
    },
  },
};
</script>

<style scoped lang="scss">
.back {
  font-family: Montserrat, sans-serif;
  margin-left: 10px;
  cursor: pointer;
}
.page-header-bg {
  background: url("../../assets/img/cta-bg-details.png");
}
</style>
