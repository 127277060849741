<template>
  <section class="bg-image ptb-100" image-overlay="8">
    <div class="background-image-wraper download-bg" style="opacity: 1"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-8">
          <div class="section-heading text-center mb-1 text-white">
            <h2 class="text-white">Descarga nuestra app en las tiendas</h2>
            <div class="action-btns mt-4">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a
                    :href="appstore"
                    target="_blank"
                    class="container-download-text d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded w-100"
                  >
                    <div class="w-100 flex align-items-center">
                      <span class="fab fa-apple icon-size-sm mr-3"></span>
                      <div class="download-text text-left inline-block">
                        <small>Descargar desde</small>
                        <h5 class="mb-0">App Store</h5>
                      </div>
                    </div>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    :href="playstore"
                    target="_blank"
                    class="container-download-text d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded w-100"
                  >
                    <div class="w-100 flex align-items-center">
                      <span class="fab fa-google-play icon-size-sm mr-3"></span>
                      <div
                        class="download-text text-left inline-block w-fit-content"
                      >
                        <small>Descargar desde</small>
                        <h5 class="mb-0">Google Play</h5>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </section>
</template>

<script>
const appstore = process.env.VUE_APP_DOWNLOAD_APPSTORE;
const playstore = process.env.VUE_APP_DOWNLOAD_PLAYSTORE;

export default {
  name: "Download",

  data() {
    return {
      appstore: appstore,
      playstore: playstore,
    };
  },
};
</script>

<style lang="scss">
.download-bg {
  background: url("../../assets/img/cta-bg.png") no-repeat center center / cover
    fixed;
  // opacity: 1;
}
</style>
