import Vue from "vue";
import VueRouter from "vue-router";
import IndexOne from "../pages/indices/Index.vue";

import Page404 from "../pages/utilities/Page404.vue";
import BeCuponDetails from "../pages/BeCuponDetails.vue";
import BeCuponComprar from "../pages/BeCuponComprar.vue";
import SuccessPage from "../pages/SuccessPage.vue";
import TerminosPage from "../pages/TerminosPage.vue";
import PoliticasPage from "../pages/PoliticasPage.vue";
import CuponDetails from "../pages/CuponDetails.vue";
import DeleteAccount from "../pages/DeleteAccount.vue";
import AfiliadosPage from "../pages/AfiliadosPage.vue";

import ErrorPage from "../pages/ErrorPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "IndexOne",
    component: IndexOne,
    meta: {
      title: "BeCupon | Los mejores descuentos y cupones en una sola App",
    },
  },
  {
    path: "/compras/:id",
    name: "Compras",
    component: BeCuponDetails,
    meta: { title: "Details - BeCupon" },
    props: true,
  },
  {
    path: "/comprar/:id",
    name: "Comprar",
    component: BeCuponComprar,
    meta: { title: "Comprar - BeCupon" },
    props: true,
  },
  {
    path: "/:beCuponName/:name",
    name: "cupon",
    component: CuponDetails,
    props: (route) => ({
      beCuponName: route.query.beCuponName,
      subtitle: route.query.subtitle,
      becupon_description: route.query.becupon_description,
      name: route.query.name,
      cupon_description: route.query.cupon_description,
      short_description: route.query.short_description,
      uses_available: route.query.uses_available,
      days_expires: route.query.days_expires,
      real_value: route.query.real_value,
      icon1: route.query.icon1,
      imagen1: route.query.imagen1,
      cupon_locations: route.query.cupon_locations,
      uses_cant: route.query.uses_cant,
      companyImage: route.query.companyImage,
      becuponId: route.query.becuponId,
    }),
  },
  // {
  //   path: "/inicio",
  //   name: "IndexOne",
  //   component: IndexOne,
  //   meta: {
  //     title: "BeCupon | Los mejores descuentos y cupones en una sola App",
  //   },
  // },
  {
    path: "/success",
    name: "SuccessPage",
    component: SuccessPage,
    meta: { title: "Pago exitoso" },
  },
  {
    path: "/eliminar-cuenta",
    name: "EliminarPage",
    component: DeleteAccount,
    meta: { title: "Eliminar cuenta" },
  },

  {
    path: "/formulario_de_afiliado",
    name: "AfiliadosPage",
    component: AfiliadosPage,
    meta: { title: "Forma parte de Becupon" },
  },
  {
    path: "/terminos_y_condiciones",
    name: "TerminosPage",
    component: TerminosPage,
    meta: { title: "Términos y Condiciones" },
  },
  {
    path: "/aviso_de_privacidad",
    name: "PoliticasPage",
    component: PoliticasPage,
    meta: { title: "Aviso de Privacidad" },
  },
  {
    path: "/error",
    name: "ErrorPage",
    component: ErrorPage,
    meta: { title: "Error en el proceso de pago" },
  },
  {
    path: "/*",
    name: "page404",
    component: Page404,
    meta: { title: "404" },
  },
];
const router = new VueRouter({
  //mode: 'history',
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const element = document.getElementById(to.meta.anchor);
      if (element) {
        return window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) document.title = to.meta.title;
  next();
});

export default router;
