import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

/* asset imports */

import "./assets/scss/main.scss";
/* plugins */
import VueScrollTo from "vue-scrollto";
import VueYoutube from "vue-youtube";
import BackToTop from "vue-backtotop";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { createProvider } from "./vue-apollo";

Vue.use(VueMeta);
Vue.use(VueScrollTo);
Vue.use(VueYoutube);
Vue.use(BackToTop);

gsap.registerPlugin(CSSRulePlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
