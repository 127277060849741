import { render, staticRenderFns } from "./PageHeaderDetails.vue?vue&type=template&id=28b4ad83&scoped=true&"
import script from "./PageHeaderDetails.vue?vue&type=script&lang=js&"
export * from "./PageHeaderDetails.vue?vue&type=script&lang=js&"
import style0 from "./PageHeaderDetails.vue?vue&type=style&index=0&id=28b4ad83&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b4ad83",
  null
  
)

export default component.exports