<template>
  <section class="page-header-section pt-100">
    <div class="container" style="text-align: center; margin-top: 60px">
      <hr style="margin-bottom: 5rem !important" />
      <div
        class="fa fa-triangle-exclamation icon-size-lg mr-3 inline-block color-secondary"
        style="color: green; font-size: 70px"
      ></div>
      <div class="color-secondary">
        <span style="font-size: 30px">
          Ups, ocurrió algún error en su transacción,
        </span>
        <div>Inténtelo más tarde ...</div>
      </div>
      <hr style="margin-top: 5rem !important" />
    </div>
  </section>
</template>

<script>
export default {
  name: "ErrorInfo",
  props: {},
};
</script>
<style scoped lang="scss"></style>
