<template>
  <section class="promo-section ptb-40" v-bind:class="{ 'gray-light-bg' : isGray }">
    <div class="container">
      <div class="row">
        <h2 class="color-secondary text-center w-100"><i><strong>Cupones</strong></i> disponibles</h2>
      </div>
      <div class="row">
        <cupon-card  v-for="(cupon, index) in cupons" :key="index"
        :index="index"
        :beCuponName=cupon.beCuponName
        :subtitle=cupon.subtitle
        :becupon_description=cupon.becupon_description
        :name=cupon.name
        :icon1=cupon.icon
        :imagen1=cupon.imagen
        :short_description=cupon.short_description
        :cupon_description=cupon.cupon_description
        :real_value=cupon.real_value
        :uses_available=cupon.uses_available
        :days_expires=cupon.days_expires
        :date_expire=cupon.date_expire
        :cupon_locations=cupon.cupon_locations
        :uses_cant=cupon.uses_cant
        :num_cupon=cupon.num_cupon
        :id=cupon.id
        :about=cupon.about
        />
      </div>
    </div>
  </section>
</template>

<script>
import CuponCard from "./CuponCard.vue";
import AllBeCuponArray from "../AllBeCuponArray";

import icon1 from "@../../../public/assets/img/aguacate-icon.png";
import imagen1 from "@../../../public/assets/img/aguacate.png";
import icon2 from "@../../../public/assets/img/logo-ohzone-becupon.png";
import imagen2 from "@../../../public/assets/img/logo-ohzone.png";
import icon3 from "@../../../public/assets/img/logo-sharkys-becupon.jpeg";
import imagen3 from "@../../../public/assets/img/logo-sharkys.jpeg";

export default {
  name: "CuponsList",
  data() {
      return {
        icon1: icon1,
        imagen1: imagen1,
        icon2: icon2,
        imagen2: imagen2,
        icon3: icon3,
        imagen3: imagen3,
        cupons: AllBeCuponArray.becupons.aguacate.cupons
      }
    },  
  components: {
    CuponCard
  },
  props: {
    showDesc: {
      type: Boolean,
      default: false
    },
    isGray: {
      type: Boolean,
      default: false
    },
  },
  created() {
    for(let i = 0; i < AllBeCuponArray.becupons.aguacate.cupons.length; i++) {
            if (i === 0) {
              AllBeCuponArray.becupons.aguacate.cupons[i].icon = icon1;
              AllBeCuponArray.becupons.aguacate.cupons[i].imagen = imagen1;
            } else if (i === 1) {
              AllBeCuponArray.becupons.aguacate.cupons[i].icon = icon2;
              AllBeCuponArray.becupons.aguacate.cupons[i].imagen = imagen2;              
            } else if (i === 2) {
              AllBeCuponArray.becupons.aguacate.cupons[i].icon = icon3;
              AllBeCuponArray.becupons.aguacate.cupons[i].imagen = imagen3;              
            } 
    }
  }
};
</script>

<style>
.justify-between 
{
  justify-content: space-between;
  align-items: center;
}
.border-right-my {
  border-right: 2px solid black;
}
</style>