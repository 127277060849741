<template>
  <div class="row">
    <div class="col-md-6 col-lg-6">
      <cupon-extra-info
        :icon1="icon1"
        :name="name"
        :short_description="short_description"
        :real_value="real_value"
        :beCuponName="beCuponName"
        :cupon_description="cupon_description"
        :uses_available="uses_available"
      />
    </div>
  </div>
</template>

<script>
import CuponExtraInfo from "../cuponDetails/CuponExtraInfo";
export default {
  nameComponent: "CuponHeaderCard",
  components: { CuponExtraInfo },
  props: {
    name: {
      type: String,
      required: false,
    },
    imageUrl: {
      type: String,
      required: false,
    },
    cupon_description: {
      type: String,
      required: false,
    },
    real_value: {
      type: String,
      required: false,
    },
    short_description: {
      type: String,
      required: false,
    },
    beCuponName: {
      type: String,
      required: false,
    },
    uses_available: {
      type: String,
      required: false,
    },
    icon1: {
      type: String,
      required: false,
    },
    days_expires: {
      type: String,
      required: false,
    },
    activated_cupons: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.cupons-circle {
  width: 100px;
  border-radius: 50%;
  background-color: white;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-weight: bolder;
  color: green;
  border: 1px solid;
  position: absolute;
  top: 8%;
  right: 8%;
}
.small-number {
  display: block;
  margin-top: -64px;
}
.big-number {
  font-size: 55px;
  margin-top: -10px;
  display: block;
}
.background-color {
  background-color: #fff2f6;
}
</style>
