var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"work-process-section position-relative",class:{
    'gray-light-bg': _vm.isGray,
    'pb-100': _vm.paddingBottom,
    'pt-100': _vm.paddingTop,
  },attrs:{"id":"proceso-de-uso"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row align-items-center justify-content-md-center justify-content-sm-center"},[_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"work-process-wrap"},[_c('div',{staticClass:"process-single-item"},[_c('div',{staticClass:"process-icon-item left-shape"},[_vm._m(1),_c('svg',{attrs:{"x":"0px","y":"0px","width":"312px","height":"130px"}},[_c('path',{staticClass:"dashed1",attrs:{"fill":"none","stroke":"rgb(95, 93, 93)","stroke-width":"1","stroke-dasharray":"1300","stroke-dashoffset":"0","d":"M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338"}}),_c('path',{staticClass:"dashed2",attrs:{"fill":"none","stroke":"#ffffff","stroke-width":"2","stroke-dasharray":"6","stroke-dashoffset":"1300","d":"M3.121,2.028 C3.121,2.028 1.003,124.928 99.352,81.226 C99.352,81.226 272.319,21.200 310.000,127.338 "}})])])]),_c('div',{staticClass:"process-single-item"},[_c('div',{staticClass:"process-icon-item right-shape"},[_vm._m(2),_c('svg',{attrs:{"x":"0px","y":"0px","width":"312px","height":"130px"}},[_c('path',{staticClass:"dashed1",attrs:{"fill":"none","stroke":"rgb(95, 93, 93)","stroke-width":"1","stroke-dasharray":"1300","stroke-dashoffset":"0","d":"M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"}}),_c('path',{staticClass:"dashed2",attrs:{"fill":"none","stroke":"#ffffff","stroke-width":"2","stroke-dasharray":"6","stroke-dashoffset":"1300","d":"M311.000,0.997 C311.000,0.997 313.123,123.592 214.535,79.996 C214.535,79.996 41.149,20.122 3.377,125.996"}})])])]),_vm._m(3)])]),_vm._m(4)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-9 col-lg-8"},[_c('div',{staticClass:"section-heading text-center mb-5"},[_c('h2',[_vm._v("¿Cómo hago válidos mis cupones?")]),_c('p',[_vm._v("Sigue los pasos.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"process-icon mr-4"},[_c('i',{staticClass:"fas fa-rectangle-list color-primary"})]),_c('div',{staticClass:"process-content text-left"},[_c('h5',{staticClass:"color-secondary"},[_vm._v("¿Qué necesitas?")]),_c('p',[_vm._v(" Ubica el cupón que deseas utilizar. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Google Play")]),_vm._v(" y "),_c('a',{attrs:{"href":"#"}},[_vm._v("AppStore")]),_vm._v(". ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"process-icon ml-4"},[_c('i',{staticClass:"fas fa-location-dot color-primary"})]),_c('div',{staticClass:"process-content text-right"},[_c('h5',{staticClass:"color-secondary"},[_vm._v(" Dirígete a las sucursales recomendadas ")]),_c('p',[_vm._v(" En la pantalla de cada cupón, se enlistan cada una de las sucursales recomendadas para hacer válido tu cupón. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"process-single-item"},[_c('div',{staticClass:"process-icon-item left-shape mb-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"process-icon mr-4"},[_c('i',{staticClass:"fas fa-qrcode color-primary"})]),_c('div',{staticClass:"process-content text-left"},[_c('h5',{staticClass:"color-secondary"},[_vm._v(" Escanear el QR del cupón en la sucursal ")]),_c('p',[_vm._v(" El establecimiento debe escanear tu cupón para hacer válida la promoción. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 col-lg-6"},[_c('div',{staticClass:"img-wrap"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../../assets/img/app-mobile-image-3.png"),"alt":"Cómo obtener un BeCupon?"}})])])
}]

export { render, staticRenderFns }