<script>
import gql from "graphql-tag";
import { watchEffect, computed } from "vue";
const appstore = process.env.VUE_APP_DOWNLOAD_APPSTORE;
const playstore = process.env.VUE_APP_DOWNLOAD_PLAYSTORE;

export default {
  name: "Banner",
  // apollo: {
  //   userList: gql`
  //     {
  //       userList {
  //         results {
  //           id
  //           username
  //           isActive
  //         }
  //         totalCount
  //       }
  //     }
  //   `,
  // },

  components: {},
  data: function () {
    return {
      userList: [],
      showModal: false,
      showModalBotton: true,
      usuariosActivos: 0,

      appstore: appstore,
      playstore: playstore,
    };
  },
  methods: {
    showYouTubeModal: function (e) {
      e.preventDefault();
      this.showModal = !this.showModal;
      this.showModalBotton = !this.showModalBotton;
    },
  },
  computed: {
    usuariosActivosT() {
      for (let i = 0; i < this.userList.results.length; i++) {
        if (this.userList.results[i].isActive) {
          this.usuariosActivos++;
        }
      }
      return this.usuariosActivos;
    },
  },
};
</script>

<template>
  <!--hero section start-->
  <section class="ptb-100 bg-image overflow-hidden" image-overlay="10">
    <div class="hero-bottom-shape-two hero-bottom-shape-bg"></div>

    <!--style="background: url('../../assets/img/hero-bottom-shape-2.svg')no-repeat bottom center"-->

    <div class="container">
      <div
        class="row align-items-center justify-content-lg-between justify-content-md-center justify-content-sm-center"
      >
        <div class="col-md-12 col-lg-6">
          <div class="hero-slider-content text-white py-5">
            <h1 class="text-white">Ahorra con un clic</h1>
            <p class="lead">
              Somos una cuponera digital que te brinda grandes descuentos en
              diferentes establecimientos de la región.
            </p>
            <div class="action-btns mt-4">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a
                    :href="appstore"
                    target="_blank"
                    class="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                  >
                    <span class="fab fa-apple icon-size-sm mr-3"></span>
                    <div class="download-text text-left">
                      <small>Descarga desde</small>
                      <h5 class="mb-0">App Store</h5>
                    </div>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    :href="playstore"
                    target="_blank"
                    class="d-flex align-items-center app-download-btn btn btn-brand-02 btn-rounded"
                  >
                    <span class="fab fa-google-play icon-size-sm mr-3"></span>
                    <div class="download-text text-left">
                      <small>Descarga desde</small>
                      <h5 class="mb-0">Google Play</h5>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div class="action-btns mt-3">
              <!-- <a href="#" class="btn btn-brand-03 btn-rounded mr-3">
                Download Now
                <i class="fas fa-cloud-download-alt pl-2"></i>
              </a> -->
              <div
                style="
                  position: absolute;
                  z-index: 99999;
                  top: 30px;
                  right: -11px;
                "
              >
                <span
                  @click="showYouTubeModal"
                  v-if="showModal"
                  class="fas fa-circle-xmark pl-2"
                  style="
                    position: absolute;
                    left: 84px;
                    margin-top: -26px;
                    font-size: 25px;
                    cursor: pointer;
                  "
                ></span>
                <video
                  v-if="showModal"
                  autoplay="true"
                  controls="true"
                  src="../../assets/img/BecuponVideoExplicativo.mp4"
                  style="
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    height: auto;
                  "
                ></video>
              </div>
              <div style="height: 30px">
                <a
                  v-if="showModalBotton"
                  href="#"
                  class="popup-youtube btn btn-white btn-circle btn-icon"
                  @click="showYouTubeModal"
                >
                  <i class="fas fa-play"></i>
                </a>
                <span v-if="showModalBotton" class="pl-2">Ver ahora</span>
              </div>
            </div>
            <div v-if="this.usuariosActivos > 0" class="hero-counter mt-4">
              <div class="row">
                <div class="col-6 col-sm-4">
                  <div class="counter-item d-flex align-items-center py-3">
                    <div class="single-counter-item">
                      <div v-if="this.userList">
                        <span class="h4 count-number text-white">
                          {{ this.userList.totalCount }}
                        </span>
                      </div>
                      <div v-else>
                        <span class="h4 count-number text-white">
                          Información no disponible en este momento.
                        </span>
                      </div>
                      <h6 class="text-white mb-0">Total usuarios</h6>
                    </div>
                    <span class="color-6 ml-2 p-2 rounded-circle">
                      <i class="fas fa-arrow-up icon-sm"></i>
                    </span>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <div class="counter-item d-flex align-items-center py-3">
                    <div class="single-counter-item">
                      <span class="h4 count-number text-white">1,555</span>
                      <h6 class="text-white mb-0">Total de descargas</h6>
                    </div>
                    <span class="color-6 ml-2 p-2 rounded-circle">
                      <i class="fas fa-arrow-up icon-sm"></i>
                    </span>
                  </div>
                </div>
                <div class="col-6 col-sm-4">
                  <div class="counter-item d-flex align-items-center py-3">
                    <div class="single-counter-item">
                      <span class="h4 count-number text-white">{{
                        usuariosActivosT
                      }}</span>
                      <h6 class="text-white mb-0">Usuarios Activos</h6>
                    </div>
                    <span class="color-6 ml-2 p-2 rounded-circle">
                      <i class="fas fa-arrow-up icon-sm"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="hero-counter mt-4"></div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-5">
          <div class="img-wrap">
            <img
              src="../../assets/img/app-mobile-image.png"
              alt="Te ayudamos a hacer compras inteligentes y ahorrativas"
              class="img-fluid"
            />
          </div>
        </div>

        <!-- <div class="query-wrapper">
          <h1>Cantidad de Usuarios: {{ this.userList }}</h1>
        </div> -->
      </div>
      <!--end of row-->
    </div>
    <!--end of container-->

    <!-- <YTModal url="../assets/img/BecuponVideoExplicativo.mp4" v-if="showModal" @close="showModal = false">
      This is my first modal
    </YTModal> -->
  </section>
</template>

<style lang="scss">
.medioambiente__img1 {
  width: 15%;
  height: 15%;

  @media screen and (max-width: 1024px) {
    width: 15%;
    height: 15%;
  }
}

.hero-bottom-shape-bg {
  background: url("../../assets/img/hero-bottom-shape-2.svg") no-repeat bottom
    center;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  background-color: transparent;
  width: 640px;
  height: 360px;
  margin: 0px auto;
  /*padding: 20px 30px;*/
  /*background-color: #fff;*/
  border-radius: 2px;
  /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);*/
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close-button {
  float: right;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-weight: 600;
  color: white;
}
</style>
