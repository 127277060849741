var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contact-us-section ptb-100",class:{ 'gray-light-bg': _vm.isGray },attrs:{"id":"contacto"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-around"},[_c('div',{staticClass:"col-12 pb-3 message-box",class:{
          'd-none': !_vm.isSuccess && !_vm.hasError,
          'd-block': _vm.isSuccess || _vm.hasError,
        }},[_c('div',{staticClass:"alert",class:{
            'alert-danger': _vm.hasError,
            'alert-success': _vm.isSuccess,
          }},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])]),_c('div',{staticClass:"col-md-12 col-lg-6"},[_c('div',{staticClass:"contact-us-content"},[_c('h2',[_vm._v(" Si estás interesado en formar parte de BeCupon, llena este "),_c('router-link',{attrs:{"to":"/formulario_de_afiliado"}},[_vm._v(" formulario ")]),_vm._v(" o envía un correo a "),_c('a',{attrs:{"href":"mailto:ventas@becupon.com"}},[_vm._v(" ventas@becupon.com ")])],1),_c('hr',{staticClass:"my-5"}),_vm._m(0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"contact-info-list"},[_c('li',{staticClass:"d-flex pb-3"},[_c('div',{staticClass:"contact-icon mr-3"},[_c('span',{staticClass:"fas fa-location-arrow color-primary rounded-circle p-3"})]),_c('div',{staticClass:"contact-text"},[_c('h5',{staticClass:"mb-1"},[_vm._v("Ubicación")]),_c('p',[_vm._v("Tijuana, Baja California, México")])])]),_c('li',{staticClass:"d-flex pb-3"},[_c('div',{staticClass:"contact-icon mr-3"},[_c('span',{staticClass:"fas fa-envelope color-primary rounded-circle p-3"})]),_c('div',{staticClass:"contact-text"},[_c('h5',{staticClass:"mb-1"},[_vm._v("Correo electrónico")]),_c('a',{attrs:{"href":"mailto:ventas@becupon.com"}},[_c('p',[_vm._v("ventas@becupon.com")])])])])])
}]

export { render, staticRenderFns }