<template>
    <div class="container">
        <div class="row pl-2 pr-2">
            <div class="card-content mt-4">
                <h1 class="color-secondary text-center">Información del <strong>BeCupon</strong></h1>
                <div class="m-3">
                    {{ becupon_description }}
                </div>
            </div>
        </div>
        <div class="row pl-2 pr-2">
            <card-info name="BeCupons disponibles" :cant="becuponscupons_available"/>
            <card-info name="Cupones activos" :cant="cupons_available"/>
            <card-info name="Días de expiración" :cant="days_expires"/>
            <card-info name="Fecha de Expiración" :cant="date_expire"/>
        </div>
    </div>
</template>

<script>
    import CardInfo from "./CardInfo.vue";
    
    export default {
        name: 'InfoDetails',
    components: { CardInfo },
        props: {
            cupons_available: {
                type: String,
                required: false
            },
            days_expires: {
                type: String,
                required: false
            },
            becuponscupons_available: {
                type: String,
                required: false
            },
            date_expire: {
                type: String,
                required: false
            },
            becupon_description: {
                type: String,
                required: false
            },
        }
    }
</script>
<style scoped lang="scss">
</style>