<template>
  <section
    id="pantallas-becupon"
    class="screenshots-section"
    v-bind:class="{
      'gray-light-bg': isGray,
      'pb-100': paddingBottom,
      'pt-100': paddingTop,
    }"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-9 col-lg-8">
          <div class="section-heading text-center mb-5">
            <h2>Pantallazos de <strong>BeCupon</strong></h2>
            <p>
              Te asesoramos para hacer más practico el canje de tus cupones.
            </p>
          </div>
        </div>
      </div>
      <div class="screenshot-wrap">
        <!-- <div class="screenshot-frame"></div> -->

        <carousel
          :autoplay="true"
          :dots="true"
          :loop="true"
          :responsive="{
            0: { items: 1 },
            768: { items: 3 },
            991: { items: 4 },
            1200: { items: 4 },
            1920: { items: 4 },
          }"
          :margin="0"
          :center="true"
          :nav="false"
          class="screen-carousel owl-theme dot-indicator"
        >
          <img
            src="../../assets/img/Produccion-00.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-02.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-03.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-04.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-05.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-06.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-07.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-08.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-09.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-10.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-11.png"
            class="img-fluid"
            alt="screenshots"
          />
          <img
            src="../../assets/img/Produccion-12.png"
            class="img-fluid"
            alt="screenshots"
          />
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  name: "Screenshots",
  props: {
    isGray: {
      type: Boolean,
      default: false,
    },
    paddingTop: {
      type: Boolean,
      default: true,
    },
    paddingBottom: {
      type: Boolean,
      default: true,
    },
  },
  components: { carousel },
};
</script>

<style></style>
