<script>
import gql from "graphql-tag";

export default {
  name: "Advertising",
  apollo: {
    commercialRandom: gql`
      {
        commercialRandom {
          id
          url
          image {
            id
            url
          }
          coupons {
            id
            title
          }
          clicks
        }
      }
    `,
  },

  data: function () {
    return {
      commercialRandom: [],
    };
  },
  methods: {
    moveSlider(n) {
      n = n - 1;
      this.slider.scrollTo({
        top: this.heigth * n,
        behavior: "smooth",
      });
    },
  },
};
</script>

<template>
  <div v-if="this.commercialRandom[0]" class="banner">
    <router-link
      :to="{
        name: 'Compras',
        params: { id: this.commercialRandom[0].coupons.id },
      }"
      v-if="this.commercialRandom[0].coupons"
      target="_blank"
    >
      <img
        class="banner__img"
        v-bind:src="this.commercialRandom[0].image.url"
      />
    </router-link>

    <a
      v-else-if="this.commercialRandom[0].url"
      v-bind:href="this.commercialRandom[0].url"
      target="_blank"
    >
      <img
        class="banner__img"
        v-bind:src="this.commercialRandom[0].image.url"
      />
    </a>
    <br /><br />
  </div>
</template>

<style lang="scss" scoped>
.banner {
  text-align: center;
  &__img {
    width: 1100px;
    height: 122px;
    @media screen and (max-width: 600px) {
      height: 41px;
      width: 370px;
    }

    @media screen and (min-width: 600px) and (max-width: 960px) {
      height: 67px;
      width: 600px;
    }

    @media screen and (min-width: 960px) and (max-width: 1264px) {
      height: 100px;
      width: 900px;
    }
  }
}
</style>
