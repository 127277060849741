<template>
  <div :data-value="getItem">
    <v-row v-if="isLoading">
      <p>Uploading your file, please wait...</p>
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-row>
    <v-row v-else>
      <img
        v-for="(opts, key) in imagesUploaded"
        :value="key"
        :id="key"
        :key="imagesUploaded[key]"
        :src="imagesUploaded[key]"
        class="col-md-2"
      />
      <v-file-input
        class="col-md-12"
        v-model="uploadObject"
        @change="uploadFile"
        @click:clear="clear"
        :label="label"
        :error-messages="img_validation_error"
        multiple
        truncate-length="125"
        style="align-self: flex-end"
      />
    </v-row>
  </div>
</template>

<script>
import S3 from "aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default {
  data() {
    return {
      isLoading: false,
      fileExtension: "",
      uploadObject: { name: this.obj[this.fieldName] || "" },
      imagesUploaded: [],
      img_validation_error: [],

      uploadedImagesCount: 0,
      maxImages: 2, // Número máximo de imágenes permitidas
    };
  },
  computed: {
    config() {
      return {
        bucketName: "becupon-store-qa",
        dirName: this.directory,
        region: "us-east-1",
        accessKeyId: "AKIAROQ7PYIKHHOUQVSU",
        secretAccessKey: "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",
        s3Url: this.baseURL,
      };
    },
    baseURL() {
      return "https://becupon-store-qa.s3.amazonaws.com/";
    },
    S3Client() {
      return new S3(this.config);
    },
    getItem() {
      this.obj[this.fieldName] = this.item.image;
      this.uploadObject = { name: this.item.image || "" };

      if (this.item.images !== undefined && this.item.images.length > 0) {
        for (let i = 0; i < this.item.images.length; i++) {
          this.imagesUploaded[i] = this.item.images[i].url || "";
        }
        this.uploadObject = { name: this.item.images.length + " files" || "" };
      }
      return this.item;
    },
  },
  methods: {
    clearImages() {
      this.imagesUploaded = [];
      this.uploadedImagesCount = 0; // Reinicia el contador
    },

    newFileName(file, i) {
      let nfn = `${Math.random().toString().slice(2, 8)}-${file.name}`;
      this.imagesUploaded[i] = this.url(nfn) + "." + file.type.split("/")[1];
      return nfn;
    },
    url(nfn) {
      return `${this.baseURL}${this.directory}/${nfn}`;
    },
    clear() {
      this.setPersistError(false);
    },

    isPngFile(filename) {
      const ext = filename.split(".").pop().toLowerCase();
      return ext === "png";
    },

    uploadFile() {
      this.clearImages(); // Limpia las imágenes anteriores

      // Obtiene los archivos seleccionados
      const selectedFiles = this.uploadObject;

      // Verifica que los archivos sean de tipo PNG
      const isValidFileType = selectedFiles.every((file) =>
        this.isPngFile(file.name)
      );

      if (!isValidFileType) {
        alert("Solo se permiten archivos PNG.");
        return;
      }

      if (this.uploadObject.length === 0) {
        for (let i = 0; i < this.imagesUploaded.length; i++) {
          this.imagesUploaded[i] = "";
        }
        this.setImageUpload(this.imagesUploaded);
        this.setPersistError(false);
      } else {
        this.setPersistError(false);
        let count = 0;
        let imagesToUpload = this.uploadObject.slice(0, this.maxImages); // Limita el número de imágenes a subir

        for (let i = 0; i < imagesToUpload.length; i++) {
          let file = imagesToUpload[i];
          if (file.size / 1024 > 1024) {
            this.setErrorMessage(" Las imagenes no pueden pesar más de 1 MB. ");
          } else {
            this.isLoading = true;
            let name = this.newFileName(file, i);
            let ext = "." + file.type.split("/")[1];

            let img = new Image();
            let func = this.setErrorMessage;

            console.log("this.width...", this.width);
            console.log("this.height...", this.height);

            img.onload = function () {
              console.log("img.width...", img.width);
              console.log("img.height...", img.height);
              console.log(
                `Las dimensiones de la imagen son ${img.width}x${img.height}`
              );
              this.img_validation_error =
                img.height > this.height || img.width > this.width
                  ? [
                      `La imagen debe ser ${this.height} (alto) x ${this.width} (ancho) pixeles como máximo.`,
                    ]
                  : [];
              if (600 < this.height || 600 < this.width) {
                func(
                  `La imagen debe ser 600 (alto) x 600 (ancho) pixeles como máximo.`
                );
              }
            };

            this.S3Client.uploadFile(file, name).finally(() => {
              this.fileExtension = file.type.split("/")[1];
              this.obj[this.fieldName] = `${this.url(file)}`;

              img.src = `${this.baseURL}${this.directory}/${name}${ext}`;

              count += 1;
              if (count === imagesToUpload.length) {
                this.isLoading = false;
                this.setImageUpload(this.imagesUploaded, this.cuponIndex);
              }
            });
          }
        }
      }
    },
  },
  props: {
    cuponIndex: {
      type: Number,
      required: true,
    },

    fieldName: {
      type: String,
      required: true,
    },
    obj: {
      // type: Object,
      required: true,
    },
    directory: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    setImageUpload: {
      type: Function,
      required: true,
    },
    setErrorMessage: {
      type: Function,
      required: true,
    },
    setPersistError: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
};
</script>
