<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <promo />
            <about />
            <share-photos />
            <advertising />
            <download />
            <features />
            <screenshots :is-gray="true" />
            <work-process />
            <download />
            <!-- <counter-section /> -->
            <!-- <price :is-gray="true"/> -->
            <faq />
            <testimonial />
            <!-- <team :is-gray="true" /> -->
            <!-- <sucursal /> -->
            <contact />
            <!-- <blog :is-gray="true" /> -->
            <customers />
        </div>
        <site-footer :show-subscribe="true" />
        <copyright />
    </div>
</template>

<script>
    import NavBar from "../../views/commons/NavBar";
    import Banner from "../../views/index-one/Banner";
    import Promo from "../../views/index-one/Promo";
    import About from "../../views/index-one/About";
    import SharePhotos from "../../views/index-one/SharePhotos";
    import Advertising from "../../views/index-one/Advertising";
    import Download from "../../views/index-one/Download";
    import Features from "../../views/index-one/Features";
    import Screenshots from "../../views/index-one/Screenshots";
    import WorkProcess from "../../views/commons/sections/WorkProcess";
    // import CounterSection from "../../views/index-one/CounterSection";
    //import Price from "../../views/index-one/Price";
    //import Team from "../../views/commons/sections/Team";
    import Faq from "../../views/index-one/Faq";
    import Testimonial from "../../views/index-one/Testimonial";
    import Contact from "../../views/index-one/Contact";
    //import Sucursal from "../../views/index-one/Sucursal";
    import SiteFooter from "../../views/commons/SiteFooter";
    import Copyright from "../../views/commons/Copyright";
    import Customers from "../../views/commons/sections/Customers";

    export default {
    name: "IndexOne",
    metaInfo: {
        meta: [
        { charset: 'utf-8' },
        { vmid: 'description', name: 'description', content: 'foo' }
        ]
    },        
        components: {
            NavBar,
            Banner,
            Promo,
            About,
            SharePhotos,
            Advertising,
            Download,
            Features,
            Screenshots,
            WorkProcess,
            // CounterSection,
            //Price,
            Faq,
            //Sucursal,
            Testimonial,
            //Team,
            Contact,
            Customers,
            SiteFooter,
            Copyright,
        }
    };
</script>