<template>
  <div>
    <nav-bar />
    <error-info />
    <site-footer-details :show-subscribe="true" />
    <copyright />
  </div>
</template>
<script>
import NavBar from "../views/commons/NavBar";
import SiteFooterDetails from "../components/becuponDetails/SiteFooterDetails";
import Copyright from "../views/commons/Copyright";
import ErrorInfo from "../components/payment/ErrorInfo.vue";

export default {
  name: "ErrorPage",
  components: { NavBar, SiteFooterDetails, Copyright, ErrorInfo },
};
</script>
