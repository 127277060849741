<template>
  <div class="verification-code">
    <p>
      Ingrese el código de verificación que recibió en su casilla de correo
      electrónico.
    </p>
    <div class="row pl-2 pr-2 justify-content">
      <div class="col-md-6 col-lg-6 display-contents">
        <input
          v-for="(digit, index) in verificationCode"
          :key="index"
          v-model="verificationCode[index]"
          class="form-control verification-digit custom-input"
          maxlength="1"
          @input="handleInput(index)"
          :ref="'input' + index"
        />
      </div>
    </div>
    <p v-if="verificationResultMessage" class="text-center text-danger">
      {{ verificationResultMessage }}
    </p>
    <button
      @click="verifyCode"
      class="g-recaptcha w-100 d-flex align-items-center app-download-btn btn btn-success btn-rounded mt-5"
    >
      Verificar Código
    </button>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "VerificationCode",
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      verificationCode: ["", "", "", ""],
      verificationResultMessage: "",
    };
  },
  methods: {
    handleInput(index) {
      if (this.verificationCode[index].length === 1 && index < 3) {
        const nextIndex = index + 1;
        this.verificationCode[nextIndex] = this.verificationCode[
          nextIndex
        ].substring(0, 1);
        this.$refs["input" + nextIndex][0].focus();
      }
    },
    verifyCode() {
      const code = this.verificationCode.join("");

      this.$apollo
        .mutate({
          mutation: gql`
          mutation {
            verifyRegisterCode(code: "${code}", userId: ${this.userId}) {
              ok
            }
          }
        `,
        })
        .then((response) => {
          // console.log(response);
          this.verificationResultMessage = response.data.verifyRegisterCode.ok
            ? "Código correcto."
            : "Código incorrecto.";

          if (response.data.verifyRegisterCode.ok) {
            // Emit the verification success event
            this.$emit("verification-success");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.custom-input {
  background-color: #ef025c;
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 40px;
  margin: 5px;
  padding: 10px;
  color: white;
  text-align: center;
}

.custom-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
