<template>
  <div class="d-flex flex-column flex-md-row mt-4">
    <div class="border-right-my pr-4 mr-4">
      <img
        class="inline-flex custom-image"
        style="border-radius: 8px; height: auto"
        :title="name"
        :src="icon1"
        :alt="name"
      />
    </div>
    <div class="d-flex flex-column col-md-10">
      <h5 class="mb-0" :title="name">{{ name }}</h5>
      <h5 class="color-secondary mb-0" :title="name">
        {{ short_description }}
      </h5>
      <span>
        <strong> Términos y condiciones: </strong>
      </span>
      <p>
        {{ cupon_description }}
      </p>
      <p class="">
        (Valor real:
        <strong
          >${{
            parseInt(real_value)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
          MXN</strong
        >)
      </p>
      <div class="container" style="height: 300px">
        <div class="cupons-circle">
          <span class="big-number">
            {{ uses_available }}
          </span>
          <span class="small-number">
            {{ uses_available == "1" ? "Uso" : "Usos" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CuponExtraInfo",
  props: {
    name: {
      type: String,
      required: true,
    },
    short_description: {
      type: String,
      required: true,
    },
    real_value: {
      type: String,
      required: true,
    },
    icon1: {
      type: String,
      required: true,
    },
    cupon_description: {
      type: String,
      required: true,
    },
    uses_available: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.background-color {
  background-color: white;
}

.container {
  position: relative;
}

.cupons-circle {
  width: 200px;
  border-radius: 50%;
  background-color: white;
  height: 200px;
  text-align: center;
  line-height: 100px;
  font-weight: bolder;
  color: green;
  border: 1px solid;
  top: 10%;
  right: 9%;
  position: absolute;
}

.big-number {
  font-size: 100px;
  margin-top: 40px;
  display: block;
}

.small-number {
  display: block;
  margin-top: -40px;
  font-size: 30px;
}

.custom-image {
  width: 300px; /* Establecer el ancho máximo al 90% en dispositivos móviles */
  margin-left: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .custom-image {
    width: 500px; /* Establecer el ancho máximo al 100% en escritorio */
  }
}
</style>
